import { FC } from 'react';

import {
  useProcessedProblems,
  useProcessedWorkStore,
} from '../../../studentApp/hooks';
import { squareGroupsStyle } from '../util/constants';

import { LabelAndSquares } from './labelAndSquares';
import { RegionLabel } from './regionLabel';

export const PracticeCorrectionsRegion: FC = () => {
  const { practiceCorrectionsProblems } = useProcessedProblems();
  const required = useProcessedWorkStore().practiceRequired;
  const progress = useProcessedWorkStore().examPracticeProgress;

  return required ? (
    <>
      <RegionLabel
        text={'Extra Practice'}
        score={progress}
        id="practiceProgress"
      />
      <div className="scrollable" style={squareGroupsStyle}>
        <LabelAndSquares text={''} problems={practiceCorrectionsProblems} />
      </div>
    </>
  ) : null;
};
