import { AttemptGetter } from '../getters';
import { xyz } from '../renderers';

import { BasicObject } from './basicObject';

export function getSubmitButton(
  getter: AttemptGetter,
  paintCanvas: () => void,
  submitAttempt: () => void,
  getThemeColor: () => string
): BasicObject {
  const submitButton = new BasicObject();

  submitButton.gmmName = 'submit button';

  submitButton.animates = true;

  submitButton.setAllDim(40, 40);

  submitButton.mouseDownResponse = function () {
    if (getter.st === 'c') {
      submitButton.animated = false;

      return true;
    }

    getter.grabFocus();

    submitAttempt();

    return true;
  };

  submitButton.paintMe = function (ctx) {
    xyz(
      'submit',
      ctx,
      0,
      0,
      paintCanvas,
      submitButton.animated,
      getThemeColor(),
      40,
      getter.t === 'g' && getter.st === 'c'
    );
  };

  return submitButton;
}
