import { FC, useEffect } from 'react';

import { Dialog } from '@gmm/problem';

import { useProcessedWorkStore } from '../../../studentApp/hooks/useProcessedWorkStore';
import { useProcessedStudentAppModalStore } from '../../../studentApp/hooks/useStudentAppModalStore';
import gameMaster from '../../../studentApp/legacy/gameengine';
import { bannerStore } from '../../../studentApp/stores';
import {
  NO_MODAL,
  SETTINGS,
  STATISTICS,
  EXAM_WARNING,
  CHOOSE_WORK,
  WORK_HISTORY,
  EXAMS,
  GAMES,
  INFO,
  SWITCH_CLASS,
  NEED_HELP,
  SHOW_ANSWER,
  WELCOME,
  SMILEYS,
  GAME,
  studentAppModalStore,
} from '../../../studentApp/stores/studentAppModalStore';

import { Clock } from './clock';
import { ExamHandInWarning } from './examHandInWarning';
import { examLoading as examLoadingView } from './examLoading';
import { Game } from './game';
import { Info } from './info';
import { AvailableWork } from './lists/availableWork';
import { Exams } from './lists/exams';
import { Games } from './lists/games';
import { SwitchClass } from './lists/switchClass';
import { WorkHistory } from './lists/workHistory';
import { Loading } from './loading';
import { MessageFromTeacher } from './messageFromTeacher';
import { NeedHelpModal } from './needHelpModal';
import { NewExamDialog } from './newExamDialog';
import { Overlay } from './overlay';
import { Settings } from './settings';
import { ShowAnswer } from './showAnswer';
import { Smileys } from './smileys';
import { Statistics } from './statistics';
import { TopScores } from './topScores';
import { Welcome } from './welcome';

interface ModalsProps {
  onboardingApp?: boolean;
}

export const Modals: FC<ModalsProps> = ({ onboardingApp }) => {
  const [currentModal, setCurrentModal] = studentAppModalStore(state => [
    state.currentModal,
    state.setCurrentModal,
  ]);
  const [stackedDialogs, popStackableDialog] = studentAppModalStore(state => [
    state.stackableDialogs,
    state.popStackableDialog,
  ]);
  const [stackedMessages, popStackableMessage] = studentAppModalStore(state => [
    state.stackableMessagesFromTeacher,
    state.popStackableMessageFromTeacher,
  ]);
  const [
    newExamDialogOptions,
    setNewExamDialog,
  ] = studentAppModalStore(state => [
    state.newExamDialog,
    state.setNewExamDialog,
  ]);
  const [loading, examLoading] = studentAppModalStore(state => [
    state.loading,
    state.examLoading,
  ]);
  const { isShowingModal } = useProcessedStudentAppModalStore();
  const [showTopScores, showClock] = bannerStore(state => [
    state.showTopScores,
    state.showClock,
  ]);
  const currentWorkType = useProcessedWorkStore().currentWorkType;

  const closeModal = () => {
    setCurrentModal(NO_MODAL);
  };

  const closeNewExamDialog = () => {
    setNewExamDialog(undefined);
  };

  // Close one modal at a time, starting with 'top'.
  const clickOverlayOrPressEnter = () => {
    // New Exam Dialog is at the very top
    if (newExamDialogOptions) {
      closeNewExamDialog();

      return;
    }

    // Stackable dialogs are 'on top of' stackable messages, which are themselves on top of the modal
    if (stackedDialogs.length > 0) {
      popStackableDialog();

      return;
    } else if (stackedMessages.length > 0) {
      popStackableMessage();

      return;
    }

    closeModal();
  };

  const keyDown = (e: KeyboardEvent) => {
    if (loading) {
      if (currentModal === SMILEYS) {
        clickOverlayOrPressEnter();
      }

      e.preventDefault();
      e.stopPropagation();

      return;
    }

    if (currentModal === GAME) {
      gameMaster.keyDown(e);
      e.preventDefault();
      e.stopPropagation();

      return;
    }

    // Pressing enter closes top modal,
    // except for ShowAnswer, which only closes on mouse
    // click of button or top right X to ensure no
    // accidental closing.
    if (e.key == 'Enter' && currentModal !== SHOW_ANSWER)
      clickOverlayOrPressEnter();
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    // The third argument is 'useCapture', which is set to true to ensure that the
    // event listener is triggered aggressively, before event listeners that aren't
    // the 'normal' handling of the event on the event's target element.
    if (isShowingModal) window.addEventListener('keydown', keyDown, true);

    return () => {
      window.removeEventListener('keydown', keyDown, true);
    };
  }, [isShowingModal, stackedDialogs, stackedMessages, currentModal]);

  return (
    <>
      {showTopScores && <TopScores />}
      {showClock && <Clock />}

      {isShowingModal && <Overlay close={clickOverlayOrPressEnter} />}

      {currentModal === SMILEYS && <Smileys close={closeModal} />}
      {currentModal === SETTINGS && <Settings close={closeModal} />}
      {currentModal === STATISTICS && <Statistics close={closeModal} />}
      {currentModal === EXAM_WARNING && (
        <ExamHandInWarning close={closeModal} />
      )}
      {currentModal === CHOOSE_WORK && <AvailableWork close={closeModal} />}
      {currentModal === WORK_HISTORY && <WorkHistory close={closeModal} />}
      {currentModal === EXAMS && <Exams close={closeModal} />}
      {currentModal === GAMES && <Games close={closeModal} />}
      {currentModal === INFO && <Info close={closeModal} />}
      {currentModal === SWITCH_CLASS && <SwitchClass close={closeModal} />}
      {currentModal === NEED_HELP && <NeedHelpModal close={closeModal} />}
      {currentModal === SHOW_ANSWER && <ShowAnswer close={closeModal} />}
      {currentModal === WELCOME && <Welcome close={closeModal} />}

      {stackedMessages.length > 0 && (
        <MessageFromTeacher
          text={stackedMessages[stackedMessages.length - 1]}
          close={popStackableMessage}
        />
      )}

      {stackedDialogs.length > 0 && (
        <Dialog
          alert={stackedDialogs[stackedDialogs.length - 1]}
          closer={popStackableDialog}
        />
      )}

      {!!newExamDialogOptions && (
        <NewExamDialog
          close={closeNewExamDialog}
          options={newExamDialogOptions}
        />
      )}

      {currentModal === GAME && <Game />}

      {/* full screen, locked out of app 'loading' */}
      {!onboardingApp && (loading || currentWorkType === 'NONE') && <Loading />}

      {/* lightweight, just a graphic, app still mouse/key responsive */}
      {examLoading && examLoadingView}
    </>
  );
};
