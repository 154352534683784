// For determining device touch screen capabilities
// derived from comparing the results of multiple touchscreen/non-touchscreen devices using the below url
// https://patrickhlauke.github.io/touch/pointer-hover-any-pointer-any-hover/

function getPointer(type: string): boolean {
  return window.matchMedia(type).matches;
}

export function isTouchDeviceOnly(): boolean {
  const isFine = getPointer('(pointer:fine)');
  const isCoarse = getPointer('(pointer:coarse)');

  return !isFine && isCoarse;
}

export function isTouchDevice(): boolean {
  const touchOnly = isTouchDeviceOnly();
  const isCoarse = getPointer('(pointer:coarse)');
  const anyIsCoarse = getPointer('(any-pointer:coarse)');

  return touchOnly || isCoarse || anyIsCoarse;
}
