import { CSSProperties, FC } from 'react';

import { useLayout, useProcessedWorkStore } from '../../../studentApp/hooks';
import { LANDSCAPE_SQUARES_WIDTH_PERCENTAGE } from '../../constants';

import { AssignedRegion } from './assignedRegion';
import { ExamCorrectionsRegion } from './examCorrectionsRegion';
import { ExamRegion } from './examRegion';
import { PracticeCorrectionsRegion } from './practiceCorrectionsRegion';
import { SpiralReviewRegion } from './spiralReviewRegion';

const landscape: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  flexGrow: 1,
  padding: '0px 20px 20px 20px',
  maxHeight: '100%',
  minHeight: '100%',
  width: `${LANDSCAPE_SQUARES_WIDTH_PERCENTAGE}%`,
};

const vertical: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  flexGrow: 0,
};

export const SquareRegions: FC = () => {
  const currentWork = useProcessedWorkStore().currentWorkType;
  const isLandscape = useLayout().isLandscape;
  const style = isLandscape ? landscape : vertical;

  return (
    <div style={style}>
      {currentWork === 'ASSIGNMENT' && (
        <>
          <AssignedRegion />
          <SpiralReviewRegion />
        </>
      )}

      {currentWork === 'SPIRAL_REVIEW' && <SpiralReviewRegion />}

      {currentWork === 'EXAM' && <ExamRegion />}

      {currentWork === 'CORRECTIONS' && (
        <>
          <ExamCorrectionsRegion />
          <PracticeCorrectionsRegion />
        </>
      )}
    </div>
  );
};
