// Clear all saved state, except clearing all problems, and re-request all login data

import { AlertOptions, ProblemData, cancelReadAloud } from '@gmm/problem';

import { MIN_LANDSCAPE_WIDTH, MIN_PORTRAIT_WIDTH } from '../../cnusr/constants';
import {
  LoginRequest,
  alerts,
  login,
  postHandInExam,
  postIncrementTimeSeen,
} from '../api';
import { receiveProblem } from '../api/responseHandlerShared';
import { getType } from '../hooks/useProcessedWorkStore';
import { activity, resetActivityMonitor } from '../legacy';
import { bannerState, problemState } from '../stores';
import {
  getGmm,
  getInactiveTimer,
  getLastMsgId,
  getMd5History,
  getPingId,
  getPingPoliceId,
  getSmileyIntervalTimer,
  getSmileyTimeoutTimer,
  getTimeSeenStart,
  setSmileyIntervalTimer,
  setSmileyTimeoutTimer,
  setTimeSeenStart,
} from '../stores/globalState';
import { problemJsonMap } from '../stores/problemJsonMap';
import {
  SHOW_ANSWER,
  studentAppModalState,
} from '../stores/studentAppModalStore';
import { userState } from '../stores/userStore';
import { workState } from '../stores/workStore';
import { Any, ID, NO_WORK } from '../types';

import { getTimeStampWithMillis } from './getTimestamp';

export function loginInternal(switchClasId?: Any): void {
  workState().setCurrentWork(NO_WORK);
  studentAppModalState().clear();
  problemState().clear();
  problemJsonMap.clear();
  getGmm()?.setToBlank();
  studentAppModalState().closeAll();
  turnOffAllTimers();
  resetActivityMonitor();

  const packet: LoginRequest = {
    username: userState().userName || '',
    password: userState().password || '',
    loginInternal: true,
    ss: userState().redisSessionId,
    lastMsgId: getLastMsgId(),
    // prevent cache
    a: new Date().getTime(),
  };

  if (switchClasId) packet.switchClasId = switchClasId;
  studentAppModalState().setLoading(true);
  login(packet);
}

export function harvestTimeSeen(send?: boolean): number | void {
  const timeSeenStart = getTimeSeenStart();

  if (!timeSeenStart) {
    setTimeSeenStart(new Date());

    return;
  }

  const delta = Math.max(0, new Date().getTime() - timeSeenStart.getTime());

  setTimeSeenStart(new Date());

  if (send && delta > 1500) {
    postIncrementTimeSeen({
      problemId: problemState().selectedID,
      increment: delta,
    });
  }

  return delta;
}

export function logOff(): void {
  turnOffAllTimers();
}

export const handleSevereError = (msg: string): void => {
  studentAppModalState().setLoading(false);

  gmmAlert(alerts.severeError(msg));

  return;
};

interface Reboot {
  msg: string;
}

export function reboot({ msg }: Reboot): void {
  if (msg) {
    gmmAlert({
      msg,
      reload: true,
      top: 'GMM',
    });
  }

  studentAppModalState().setLoading(false);
  studentAppModalState().setExamLoading(false);
  turnOffAllTimers();

  return;
}

export function turnOffAllTimers(): void {
  const inactiveTimer = getInactiveTimer();
  const pingId = getPingId();
  const pingPoliceId = getPingPoliceId();
  const smileyIntervalTimer = getSmileyIntervalTimer();
  const smileyTimeoutTimer = getSmileyTimeoutTimer();

  if (inactiveTimer) clearInterval(inactiveTimer);
  if (pingId) clearTimeout(pingId);
  if (pingPoliceId) clearInterval(pingPoliceId);

  if (smileyIntervalTimer) {
    clearInterval(smileyIntervalTimer);
    setSmileyIntervalTimer(undefined);
  }

  if (smileyTimeoutTimer) {
    clearTimeout(smileyTimeoutTimer);
    setSmileyTimeoutTimer(undefined);
  }
}

export function logMd5(restoreId: ID, md5: string | undefined): void {
  const timestamp = getTimeStampWithMillis();
  const value = `${timestamp} ${restoreId} set to ${md5}\n`;

  getMd5History().push(value);
}

export function logHistory(str: string): void {
  const timestamp = getTimeStampWithMillis();
  const value = `${timestamp} ${str}\n`;

  getMd5History().push(value);

  if (str.indexOf('submit #') === 0) {
    logProblemsAndWork('on submit,');
  }
}

export function logProblemsAndWork(pre: string): void {
  const keysString = Object.keys(problemState().problems).join(', ');
  const work = workState().currentWork;

  logHistory(
    `${pre} Work Type: ${getType(work)}  Work Name: ${work.name}  Work ID: ${
      work.workId
    }  Restores (Problems): ${keysString}`
  );
}

export function getHistory(): string {
  return JSON.stringify(getMd5History(), null, 2);
}

export function processAjaxFailure(jqXhr: JQuery.jqXHR<any>): boolean {
  switch (jqXhr.status) {
    case 401: {
      reboot({
        msg: 'Session Expired',
      });

      return true;
    }

    default: {
      return false;
    }
  }
}

interface ReplaceProblem {
  id: number;
  p: ProblemData;
}

export interface ShowAnswer {
  // json for display of answer to current problem on showAnswerModal
  answerJson: ProblemData;

  // Use this to replace current problem when student is done looking at answer.
  replaceProblem: ReplaceProblem;

  replacementsUsedToday: number;
}

export function showAnswer({
  answerJson,
  replaceProblem,
  replacementsUsedToday,
}: ShowAnswer): void {
  if (!answerJson || !replaceProblem) return;

  bannerState().setReplacementsUsedToday(replacementsUsedToday);

  // tuck away the problem to be replaced when student is done looking at answer
  studentAppModalState().setReplacementProblem({
    id: replaceProblem.id,
    problem: replaceProblem.p,
    performingTargetedOverride: true,
  });

  studentAppModalState().setAnswerToCurrentProblem(answerJson);

  studentAppModalState().setCurrentModal(SHOW_ANSWER);

  // SendObject sets loading to true, expects callback to set it to false.
  studentAppModalState().setLoading(false);
}

export function animateReplaceProblem(): void {
  const $canvas = $('.supports-animated-replacement');

  // Ensure there is only one listener
  $canvas.off('transitionend').on('transitionend', finishAnimateReplaceProblem);

  $canvas.css({
    transition: 'transform .6s',
    'transform-style': 'preserve-3d',
    transform: 'translateX(-50%) rotateY(90deg)',
  });
}

function finishAnimateReplaceProblem(): void {
  // current problem state switched to replacement
  const problem = studentAppModalState().replacementProblem;

  if (problem) {
    problemState().resetSquare(`${problem.id}`);
    receiveProblem(problem);
    studentAppModalState().setReplacementProblem(undefined);
  }

  const $canvas = $('.supports-animated-replacement');

  $canvas.css('transform', 'translateX(0%) rotateY(0deg)');
}

export function gmmAlert({
  msg,
  top = '',
  style = 'green',
  reload = false,
  reloadIsInternal = false,
  removeWhenAnotherDialogShows = false,
}: AlertOptions): void {
  cancelReadAloud();

  if (msg.indexOf('Invalid:') > -1 && (!top || top === 'Message')) {
    const i = msg.indexOf('Invalid:');

    msg = msg.substring(i + 9);
    top = 'Invalid';
  } else if (msg.indexOf('FYI:') > -1 && (!top || top === 'Message')) {
    const i = msg.indexOf('FYI:');

    msg = msg.substring(i + 5);
    top = 'FYI';
  }

  if (!top || top === '') top = 'GMM';

  studentAppModalState().addStackableDialog({
    msg,
    style,
    top,
    reload,
    reloadIsInternal,
    removeWhenAnotherDialogShows,
  });

  if (reload) {
    turnOffAllTimers();
    setTimeout(window.location.reload, 10 * 60000);
  }
}

export function submitTest(): void {
  activity();

  const work = workState().currentWork;

  if (work.type !== 'EXAM') return;

  const exam = work;

  postHandInExam(exam.sitId!);
  problemState().clear();
  workState().setCurrentWork(NO_WORK);
  getGmm()?.setToBlank();
  loginInternal();
}

// We have this sort of info from useLayout, but sometimes need it outside of
// React components.
export const isMobile = (): boolean => {
  return window.innerWidth < MIN_PORTRAIT_WIDTH;
};

export const isLandscape = (): boolean => {
  return window.innerWidth >= MIN_LANDSCAPE_WIDTH;
};
