import { ClickOrTouchEvent } from '..';
import { roundRect, roundRect2 } from '../renderers';
import { READ_ALOUD_HIGHLIGHT_COLOR } from '../state';

export type MousePointer = 'default' | 'grab' | 'grabbing';

export function getBasicObject(x?: number, y?: number): BasicObject {
  return new BasicObject(x || 0, y || 0);
}

export class BasicObject {
  gmmName = 'a basic object';

  viewportX: number;
  viewportY: number;
  viewportW = 0;
  viewportH = 0;
  // A partial analysis of BasicObject usage of width and height
  // shows that with some work, they could be fully removed (in
  // favor of using only viewportW) but this is a
  // target for future cleansing.
  width = 0;
  height = 0;
  viewportSlideX = 0;
  viewportSlideY = 0;

  visible = true;
  skipClip = false;
  rounded = false;

  // When viewportMargin > 0, a rectangle is painted around BasicObject using viewportMarginColor
  viewportMargin = 0;
  viewportMarginColor = 'black';

  // Unclear why we have two background colors, fill and viewportFill.
  // Target for future cleansing.
  fill?: string;
  viewportFill?: string;

  highlight = false;

  // Color of text painted on top of BasicObject. Only needed by 'text' object (getText).
  // Target for future cleansing.
  fillStyle?: string;

  // Used during obscure gridAttemptGetter focus logic.
  // Target for future cleansing.
  enableOnTouch?: boolean;
  disabled?: boolean;
  enable?(): void;

  children: BasicObject[] = [];
  // Two kinds of parents is...
  // Target for future cleansing.
  parent?: BasicObject;
  locationParent?: BasicObject;
  paintParentFirst = false;

  // BasicObjects that animate should have distinct logic in paintMe to paint differently
  // while animated is true (for 400ms after click). Usually this means painting a different background.
  // animates: Does this animate on click?
  // animated: Is this animated right now?
  animates = false;
  animated = false;

  mouseIsOver = false;

  // We know that only child class AttemptGetter uses this property,
  // so it shouldn't be needed here. However, the handful of legacy
  // attempt getters that haven't been ported to AttempGetter files
  // rely on 'paintPre' automatically detecting whether this field
  // is 'c' and thus requires fading the graphics context.
  // Eventually, delete this field and rely on 'paintPre' implementation in
  // AttemptGetter class.
  st?: string;

  // Track scaling down from calls to setMaxWidth, for usage in MathImage
  // for tts highlighting and also obscure ancient grid internal logic.
  scaledDown = 1;

  constructor(x = 0, y = 0) {
    this.viewportX = x;
    this.viewportY = y;
  }

  layoutChildrenLR(margin = 0): void {
    if (this.children.length === 0) return;
    this.children[0].viewportX = 0;

    for (let a = 1; a < this.children.length; a++) {
      const next = this.children[a];
      const previous = this.children[a - 1];

      next.viewportX =
        previous.gR() + ((margin && previous.viewportW) > 0 ? margin : 0);
    }
  }

  layoutChildrenUD(margin = 0): void {
    if (this.children.length === 0) return;
    this.children[0].viewportY = 0;

    for (let a = 1; a < this.children.length; a++) {
      const next = this.children[a];
      const previous = this.children[a - 1];

      next.viewportY =
        previous.gB() + ((margin && previous.viewportH) > 0 ? margin : 0);
    }
  }

  getMaxChildViewportWidth(): number {
    let max = 0;

    for (let a = 0; a < this.children.length; a++) {
      max = Math.max(max, this.children[a].viewportW);
    }

    return max;
  }

  setFillStyleRecursive(fillStyle: string): void {
    this.fillStyle = fillStyle;
    if (this.children.length === 0) return;

    for (let a = 0; a < this.children.length; a++) {
      this.children[a].setFillStyleRecursive(fillStyle);
    }
  }

  sizeMeToLowestAndRightmostChildren(): void {
    if (this.children.length === 0) return;
    let rightmost = 0;
    let lowest = 0;

    this.children.forEach(child => {
      rightmost = Math.max(rightmost, child.gR());
      lowest = Math.max(lowest, child.gB());
    });
    this.viewportW = rightmost;
    this.viewportH = lowest;
  }

  // If obscure logic fails in some non-text situations,
  // you may want to try `sizeMeToLowestAndRightmostChildren`
  sizeMeToFitChildren(): void {
    if (this.children.length === 0) return;
    let upperHeight = 0;
    let lowerHeight = 0;
    let w = 0;

    for (let a = 0; a < this.children.length; a++) {
      w = Math.max(w, this.children[a].gR());

      const height = this.children[a].gB();
      let childUpperHeight = height / 2;
      let childLowerHeight = height / 2;

      // Check for non vertically centered
      const vertBaseLine = this.children[a].getVertToBaseline?.();

      if (vertBaseLine) {
        const diff = vertBaseLine - childUpperHeight;

        childUpperHeight += diff;
        childLowerHeight -= diff;
      }

      upperHeight = Math.max(upperHeight, childUpperHeight);
      lowerHeight = Math.max(lowerHeight, childLowerHeight);
    }

    this.setAllDim(w, upperHeight + lowerHeight);
  }

  // helper method for sizeMeToFitChildren
  getVertToBaseline(): number {
    let vertToBaseline = 0;

    for (let a = 0; a < this.children.length; a++) {
      vertToBaseline = Math.max(
        vertToBaseline,
        this.children[a].getVertToBaseline()
      );
    }

    if (!vertToBaseline && !this.viewportH) return 0;

    return Math.max(vertToBaseline ?? 0, (this.viewportH ?? 0) / 2);
  }

  // distance to rightmost edge
  gR(): number {
    return this.viewportX + this.viewportW;
  }

  // distance to bottom
  gB(): number {
    return this.viewportY + this.viewportH;
  }

  paintChildren(ctx: CanvasRenderingContext2D): void {
    this.children.forEach(child => child.paint(ctx));
  }

  paintViewportMargin(ctx: CanvasRenderingContext2D): void {
    if (this.viewportMargin > 0) {
      ctx.save();

      const inset = 0.5 * this.viewportMargin;

      ctx.translate(inset, inset);
      ctx.beginPath();
      ctx.strokeStyle = this.viewportMarginColor;
      ctx.lineWidth = this.viewportMargin;

      if (!this.rounded) {
        ctx.rect(
          inset,
          inset,
          this.viewportW - 2 * inset,
          this.viewportH - 2 * inset
        );
      } else {
        const round = 6;

        roundRect(
          ctx,
          inset,
          inset,
          this.viewportW - (2 * inset + 1),
          this.viewportH - (2 * inset + 1),
          round
        );
      }

      ctx.stroke();
      ctx.restore();
    }
  }

  // BasicObjects create their own paintMe functions if they have unique painting logic
  paintMe?(ctx: CanvasRenderingContext2D): void;

  // slightly fade getters that are correct (when not testing) to show disabled
  paintPre(ctx: CanvasRenderingContext2D): void {
    // this status only occurs when not testing
    if (this.st === 'c') {
      ctx.globalAlpha = 0.7;
    }

    if (this.scaledDown !== 1) {
      ctx.save();
      ctx.scale(this.scaledDown, this.scaledDown);
    }
  }

  paintPost(ctx: CanvasRenderingContext2D): void {
    if (this.scaledDown !== 1) ctx.restore();
  }

  paintPostClip?(ctx: CanvasRenderingContext2D): void;

  paint(ctx: CanvasRenderingContext2D): void {
    if (!this.visible) {
      return;
    }

    ctx.save();
    ctx.beginPath();
    ctx.translate(this.viewportX, this.viewportY);

    // solid color for back of viewport
    let fill = this.viewportFill || this.fill;

    if (this.highlight) {
      fill = READ_ALOUD_HIGHLIGHT_COLOR;
    }

    if (fill) {
      ctx.fillStyle = fill;
      const inset = 0.5 * this.viewportMargin;

      if (!this.rounded) {
        ctx.fillRect(
          inset,
          inset,
          this.viewportW - 2 * inset,
          this.viewportH - 2 * inset
        );
      } else {
        roundRect2(
          ctx,
          inset,
          inset,
          this.viewportW - 2 * inset,
          this.viewportH - 2 * inset,
          6,
          fill
        );
      }
    }

    ctx.save();
    ctx.translate(this.viewportMargin, this.viewportMargin);
    ctx.beginPath();

    // establish the clip (if not overridden)
    ctx.rect(
      0,
      0,
      this.viewportW - this.viewportMargin * 2,
      this.viewportH - this.viewportMargin * 2
    );

    if (!this.skipClip) {
      ctx.clip();
    }

    ctx.beginPath();

    ctx.translate(this.viewportSlideX, this.viewportSlideY);

    this.paintPre?.(ctx);

    if (this.paintParentFirst) {
      this.paintMe?.(ctx);
      this.paintChildren(ctx);
    } else {
      this.paintChildren(ctx);
      this.paintMe?.(ctx);
    }

    this.paintPost?.(ctx);

    ctx.restore();

    this.paintViewportMargin(ctx);

    if (this.paintPostClip) {
      ctx.translate(this.viewportSlideX, this.viewportSlideY);
      this.paintPostClip(ctx);
    }

    ctx.restore();
  }

  // This parent BasicObject will not resize itself to fit its children -- sizing requires a call to sizeMeToFitChildren (or other sizing logic)
  add(child: BasicObject, index?: number, slide = false): void {
    if (index !== undefined) {
      if (!slide) this.children[index] = child;
      else this.children.splice(index, 0, child);
    } else {
      this.children.push(child);
    }

    child.locationParent = this;
  }

  removeAll(): void {
    this.children = [];
  }

  // negative slides right, up
  slide(dx: number, dy: number): void {
    this.viewportSlideX += dx;

    if (this.viewportSlideX > 0) {
      this.viewportSlideX = 0;
    } else {
      const max = this.width - this.viewportW;

      if (max > 0) {
        if (this.viewportSlideX < -max) {
          this.viewportSlideX = -max;
        }
      } else {
        this.viewportSlideX = 0;
      }
    }

    this.viewportSlideY += dy;

    if (this.viewportSlideY > 0) {
      this.viewportSlideY = 0;
    } else {
      const max = this.height - this.viewportH;

      if (max > 0) {
        if (this.viewportSlideY < -max) {
          this.viewportSlideY = -max;
        }
      } else {
        this.viewportSlideY = 0;
      }
    }
  }

  centerVertically(): void {
    const middleY = (this.viewportH - this.viewportMargin * 2) / 2;

    for (let x = 0; x < this.children.length; x++) {
      this.children[x].viewportY = middleY - 0.5 * this.children[x].viewportH;
    }
  }

  centerHorizontally(): void {
    const middleX = (this.viewportW - this.viewportMargin * 2) / 2;
    let minX = 0;

    for (let x = 0; x < this.children.length; x++) {
      const useX = middleX - 0.5 * this.children[x].viewportW;

      this.children[x].viewportX = useX;
      minX = Math.min(minX, this.children[x].viewportX);
    }

    if (minX < 0) {
      for (let x = 0; x < this.children.length; x++) {
        this.children[x].viewportX += -minX;
      }
    }
  }

  setAllDim(w: number, h?: number): void {
    h = h || w;
    this.viewportW = w;
    this.width = w;
    this.viewportH = h;
    this.height = h;
  }

  setMouseIsOver(
    over: boolean,
    results: {
      kill: boolean;
      repaint: boolean;
      mousePointerChangeTo?: MousePointer;
    }
  ): void {
    this.children.forEach(child => {
      child.setMouseIsOver(over, results);
    });

    if (over === this.mouseIsOver) {
      return;
    }

    results.repaint = true;
    this.mouseIsOver = over;

    if (over) return;

    results.mousePointerChangeTo = 'default';
  }

  // BasicObject users may attach a version of mouseMoveResponse
  // which will automatically execute whenever a mouseMove occurs
  // on the object.
  mouseMoveResponse?(): void;

  // Only called when a 'hit' (mouseMove) has already been determined for this BasicObject.
  // Execute any 'hit' logic for this BasicObject, or if there isn't any, check for mouseMove
  // on children.
  mouseMove(
    parentX: number,
    parentY: number,
    results: {
      kill: boolean;
      repaint: boolean;
      mousePointerChangeTo?: MousePointer;
    }
  ): void {
    if (!this.isEnabled()) return;

    const x =
      parentX - this.viewportX - this.viewportSlideX - this.viewportMargin;
    const y =
      parentY - this.viewportY - this.viewportSlideY - this.viewportMargin;

    // current level takes precedent over child levels during mouseMove
    if (this.mouseMoveResponse) {
      results.kill = true;

      // Did mouse enter (otherwise, no change, ignore)
      if (!this.mouseIsOver) {
        this.setMouseIsOver(true, results);
        this.mouseMoveResponse();
      }

      return;
    }

    // reverse loop: if there are overlaps, later adds are 'on top'
    for (let a = this.children.length - 1; a > -1; a--) {
      const child = this.children[a];

      if (
        x >= child.viewportX &&
        x < child.viewportX + child.viewportW &&
        y >= child.viewportY &&
        y < child.viewportY + child.viewportH
      ) {
        child.mouseMove(x, y, results);
      } else {
        child.setMouseIsOver(false, results);
      }
    }
  }

  // BasicObject users may attach a version of mouseUpResponse
  // which will automatically execute whenever a mouseup/touchup occurs
  // on the object
  mouseUpResponse?(): void;

  mouseUp(parentX: number, parentY: number): void {
    if (!this.isEnabled()) return;

    const x =
      parentX - this.viewportX - this.viewportSlideX - this.viewportMargin;
    const y =
      parentY - this.viewportY - this.viewportSlideY - this.viewportMargin;

    this.mouseUpResponse?.();

    // reverse loop: if there are overlaps, later adds are 'on top'
    for (let a = this.children.length - 1; a > -1; a--) {
      const child = this.children[a];

      if (
        x >= child.viewportX &&
        x < child.viewportX + child.viewportW &&
        y >= child.viewportY &&
        y < child.viewportY + child.viewportH
      ) {
        child.mouseUp(x, y);
      }
    }
  }

  // BasicObject users may attach a version of mouseDownResponse
  // which will automatically execute whenever a mouse/touch occurs
  // on the object
  mouseDownResponse?(x?: number, y?: number, evt?: ClickOrTouchEvent): boolean;

  // Only called when a 'hit' has already been determined for this BasicObject.
  // See if any children are hit. If not, execute any 'hit' logic for this BasicObject.
  mouseDown(
    parentX: number,
    parentY: number,
    paintCanvas: () => void,
    evt?: ClickOrTouchEvent
  ): boolean {
    let x = 0;
    let y = 0;

    if (this.isEnabled()) {
      if (parentX) {
        x =
          parentX - this.viewportX - this.viewportSlideX - this.viewportMargin;
        y =
          parentY - this.viewportY - this.viewportSlideY - this.viewportMargin;

        // reverse loop: if there are overlaps, later adds are 'on top'
        for (let a = this.children.length - 1; a > -1; a--) {
          const c = this.children[a];

          if (
            c.gmmName === 'problemPanel' ||
            (x >= c.viewportX && x < c.viewportX + c.viewportW)
          ) {
            if (
              c.gmmName === 'problemPanel' ||
              (y >= c.viewportY && y < c.viewportY + c.viewportH)
            ) {
              const kill = c.mouseDown(x, y, paintCanvas, evt);

              if (kill) {
                return kill;
              } else if (
                this.gmmName === 'numberLine' &&
                c.gmmName === 'nlGetters'
              )
                return false;
            }
          }
        }
      }

      let kill = false;

      if (this.mouseDownResponse) {
        kill = this.mouseDownResponse(x, y, evt);

        if (this.animates) {
          this.animate(paintCanvas);
        }

        return kill;
      }
    } else if (this.enableOnTouch) {
      this.disabled = false;
      if (this.enable) this.enable();

      return true;
    }

    return false;
  }

  // BasicObject descendants may attach a version of touhcStartResponse
  // which will automatically execute whenever a touch starts
  // on the object. This should be used rarely, such as for DragnDrop.
  touchStartResponse?(): boolean;

  touchStart(parentX: number, parentY: number): boolean {
    let x = 0;
    let y = 0;

    if (this.isEnabled()) {
      if (parentX) {
        x =
          parentX - this.viewportX - this.viewportSlideX - this.viewportMargin;
        y =
          parentY - this.viewportY - this.viewportSlideY - this.viewportMargin;

        // reverse loop: if there are overlaps, later adds are 'on top'
        for (let a = this.children.length - 1; a > -1; a--) {
          const c = this.children[a];

          if (
            c.gmmName === 'problemPanel' ||
            (x >= c.viewportX && x < c.viewportX + c.viewportW)
          ) {
            if (
              c.gmmName === 'problemPanel' ||
              (y >= c.viewportY && y < c.viewportY + c.viewportH)
            ) {
              const kill = c.touchStart(x, y);

              if (kill) return kill;
            }
          }
        }
      }

      if (this.touchStartResponse) {
        return this.touchStartResponse();
      }
    }

    return false;
  }

  isEnabled(): boolean {
    if (this.disabled) return false;

    return this.locationParent ? this.locationParent.isEnabled() : true;
  }

  isRow(): boolean {
    return this.gmmName.includes('row');
  }

  getRow(): BasicObject | undefined {
    if (this.isRow()) return this;

    if (this.parent) return this.parent.getRow();

    return undefined;
  }

  // BasicObjects that animate need to be able to demand a canvas-level repaint
  // when animation clock expires.
  animate(paintCanvas: () => void): void {
    if (!this.animates) {
      return;
    }

    this.animated = true;
    setTimeout(() => {
      this.animated = false;
      paintCanvas();
    }, 400);
  }

  cancelAnimation(): void {
    this.animated = false;
  }

  setHighlight(b: boolean): void {
    this.highlight = b;
  }

  getPaintedCharCount(): number {
    return this.children.reduce(
      (acc, child) => acc + child.getPaintedCharCount(),
      0
    );
  }

  /*
   * Read aloud highlights words as they are read.
   * The browser's SpeechSynthesizer fires an 'onboundary' event
   * when it starts reading a word, supplying the location/index
   * of the first character of the word.
   *
   * TTS uses getCharacterCount on each BasicObject
   * associated with the TTS to identify which BasicObject contains
   * the target location/index. Once the BasicObject is identified,
   * it calls setHighlightReadAloud on that BasicObject, passing
   * the target a relative location/index. The BasicObject then probes for
   * the location/index among its children, eventually telling a
   * child to highlight itself starting at a relative target index.
   *
   * Note that each BasicObject considers its character indexes
   * to start at 'index 0.'
   *
   * Ultimately, the highlighted BasicObject is usually a text element.
   * See the getText method in the index.js for implementation of
   * painting the highlighting of one word.
   */
  setHighlightReadAloud(start: number): void {
    this.clearHighlightReadAloud();

    if (this.children.length === 0) this.setHighlight(true);

    for (let x = 0; x < this.children.length; x++) {
      const child = this.children[x];
      const charCount = child.getPaintedCharCount();

      if (start < charCount) {
        child.setHighlightReadAloud(start);
        break;
      }

      // Since each BasicObject considers itself to start at 'index 0',
      // we need to subtract the count of the characters in the BasicObject
      // that we just handled and did not use.
      start -= charCount;
    }
  }

  clearHighlightReadAloud(): void {
    this.setHighlight(false);
    this.children.forEach(child => child.clearHighlightReadAloud());
  }

  /**
   * Resize self to fit maximimum width, paint self 'shrunken' to fit
   * new size.
   *
   * WARNING: not helpful for any BasicObject that needs hit zones
   * (buttons, text entry, etc.)
   *
   * Only downsizes.
   *
   * @param maxWidth Only impactful if maxWidth < viewportW
   */
  setMaxWidth(maxWidth: number): void {
    if (maxWidth >= this.viewportW) return;

    const reductionScale = maxWidth / this.viewportW;

    this.viewportW = maxWidth;
    this.viewportH *= reductionScale;

    this.scaledDown *= reductionScale;
  }
}

export function getFillerBasicObject(
  width: number,
  height: number
): BasicObject {
  const ret = new FillerBasicObject();

  ret.setAllDim(width, height);

  return ret;
}

// FillerBasicObject sometimes fakes membership as a line element to
// seamlessly utilize pre-existing positioning logic.
// A consequence is that it must implement expected line
// element functions setLine, buildSizeRecursive, setEditable
// and setFont
class FillerBasicObject extends BasicObject {
  setLine(): void {}
  buildSizeRecursive(): void {}
  setEditable(): void {}
  setFont(_font: any): void {}
}
