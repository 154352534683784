import $ from 'jquery';

import { ImageDTO } from '..';
import { problemModalState } from '../modals';
import { buildTTSDTO, TTSDTO } from '../readAloud';
import { roundRect } from '../renderers';
import { BasicObject, getSubmitButton, MousePointer } from '../uiObjects';
import { decodeString } from '../utils';

import { AttemptGetter, AttemptGetterStatus, BaseDTO } from './attemptGetter';

export interface DragDropController {
  isDragging(): boolean;
}

interface DragDropGetterDependencies {
  // reusing 'grabFocus' used by other getters in 'mega file'
  grabFocus(getter: AttemptGetter): void;
  // for aquiring 'backing' image such as 'Least --> Greatest' or a table
  getProbImage(data: any, x?: number, y?: number): BasicObject;
  // for building BasicObjects to render cargo
  getStaticPanel(data: any): BasicObject;
  getStaticPanelFromText(
    text: string,
    font?: string,
    tts?: TTSDTO
  ): BasicObject;
  // for getSubmitButton
  submitAttempt(): void;
  getThemeColor(): string;
  // for getSubmitButton
  paintCanvas(): void;
  // to re-use our RATIO logic when we add a floating panel to the dom at drag start
  resizeCanvas(canvas: HTMLElement, w: number, h: number): void;
  // to change mouse pointer over canvas
  $canvas: JQuery<HTMLElement>;
}

interface DTO extends BaseDTO {
  prompt: string;
  // width and height, not including prompt and submit button
  w: number;
  h: number;
  // Remove restoreId if we ever fully commit to base 64 encoded images
  restoreId?: number;
  testing: boolean;

  // Carries base 64 encoded image data (if that is turned on)
  backingImageDTO?: ImageDTO;

  // rectangles containing 'cargo' (panel suppliers): sources and targets
  locations: LocationFromServer[];
  // internal space between location border and location cargo
  padding: number;
  // 'Cargo' is a panel supplier that can be dragged between locations.
  // This inbound pair of cargo-related arrays comes from java side jsonification of Map<Integer, JSONObject>.
  // 'cargoIndexes' appear here as erratic/random integers to obfuscate answer,
  // but the order in which the indexes are shipped corresponds to the order in which
  // their corresponding panel suppliers are shipped.
  cargoIndexes: number[];
  cargoPanels: any[]; // 'any' = java side jsonification of PanelSupplier, not modeled on js
  backingHeight: number;
  // Server doesn't originally send this image-based object, but
  // once it gets built client-side on the first load of this getter, we don't
  // want it to get loaded again later, such as when students switch
  // between problems, so we add it to the 'DTO' here, which will ensure
  // it gets saved locally by the app using problem js
  backing?: any;
}

interface LocationFromServer {
  x: number;
  y: number;
  w: number;
  h: number;
  // if greater than -1, index of panel supplier originally located in this location (pre client dragging)
  sourceCargo: number;
  // if greater than -1, index of panel supplier currently located in this location (possibly started here, and possibly dragged here)
  currentCargo: number[];
  // not set if testing or if unsubmitted
  correct?: boolean;
  // if greater than -1, index of panel supplier incorrectly submitted
  // not set if testing or if unsubmitted or if submitted correctly
  incorrectCargoSubmission?: number[];
  // some Targets accept more than one cargo
  cargoLimit: number;
  // some Targets are choosy: they do not permit some Sources
  allowedCargo?: number[];
  // some Sources can provide multiple instances of the same cargo
  infinite: boolean;
}

export class DragDropGetter extends AttemptGetter implements DragDropGetter {
  VERTICAL_SPACE_AFTER_PROMPT = 12;
  VERTICAL_SPACE_BEFORE_SUBMIT_BUTTON = 20;

  problemJS: DragDropGetterDependencies;

  dto: DTO;

  locations = new Array<Location>();
  cargo = new Map<number, BasicObject>();

  // A DragDropGetter registers itself with index.js as the current dragDropController,
  // then manages drag state with 'dragging,' 'currentDraggingSource,' and 'mostRecentMouseDown'
  dragging = false;
  currentDraggingSource: Location | undefined;
  mostRecentMouseDown?: { x: number; y: number };
  // The canvas that gets clicked to initiate a drag. We use this to find an enclosing
  // HTML element for insertion of our temporary dragged canvas, and also for positioning
  // that temporary dragged canvas.
  sourceCanvas?: any;

  constructor(
    problemJS: DragDropGetterDependencies,
    dtoFromServer: DTO,
    status: AttemptGetterStatus,
    changeHandler: any,
    x?: number,
    y?: number
  ) {
    super(dtoFromServer, status, changeHandler, x || 0, y || 0);
    this.problemJS = problemJS;
    this.dto = dtoFromServer;

    this.dto.cargoIndexes.forEach((key, index) => {
      // Ignore tts for cargo
      const cargoPanel = dtoFromServer.cargoPanels[index];

      cargoPanel.tts = undefined;
      this.cargo.set(key, problemJS.getStaticPanel(cargoPanel));
    });

    let decoded = decodeString(this.dto.prompt);

    // Target for cleansing: why does decodeString possibly return undefined?
    // But there is at least one test of decodeString that expects undefined, so for now, keep it happy.
    if (decoded === undefined) decoded = this.dto.prompt;
    const prompt = this.problemJS.getStaticPanelFromText(
      decoded,
      undefined,
      buildTTSDTO(decoded)
    );

    prompt.viewportH += this.VERTICAL_SPACE_AFTER_PROMPT;
    this.add(prompt);

    const dragZoneContainer = new BasicObject();

    dragZoneContainer.viewportY = prompt.viewportH;
    dragZoneContainer.viewportW = dtoFromServer.w;
    dragZoneContainer.viewportH = dtoFromServer.h;

    // We support 'backing' image data arriving both as base 64
    // encoded strings and as data to build a
    // callback url. If we haven't built the backing yet
    // and there is no 'backingImage' from the server, then we are using the
    // old url-based method and we need to set up the backingImage object here.
    // Remove this when we permanently convert to only using base 64.
    if (!this.dto.backingImageDTO)
      this.dto.backingImageDTO = {
        w: dtoFromServer.w,
        h: dtoFromServer.backingHeight,
        restoreId: dtoFromServer.restoreId,
        test: dtoFromServer.testing,
      };

    // DragDropGetters have two main ui areas (aside from prompt and submit button):
    // 'Top' for sources (Locations that start with cargo)
    // 'Bottom' for targets and their surrounding image visual, called 'backing'
    if (!this.dto.backing)
      this.dto.backing = problemJS.getProbImage({
        image: this.dto.backingImageDTO,
      });

    // We paint the backing immediately below the 'Top' (where we paint sources)
    this.dto.backing.viewportY = dtoFromServer.h - dtoFromServer.backingHeight;

    dragZoneContainer.paintPre = (ctx: CanvasRenderingContext2D) =>
      this.dto.backing.paint(ctx);

    // Locations have location and size pre-deterimined on server so that they
    // appear exactly where expected within the 'backing' image
    dtoFromServer.locations.forEach(inboundLocation => {
      const location = new Location(
        inboundLocation,
        this,
        dtoFromServer.padding
      );

      this.locations.push(location);
      dragZoneContainer.add(location);
    });

    this.add(dragZoneContainer);

    this.viewportW = Math.max(dtoFromServer.w, prompt.viewportW);
    this.viewportH = dtoFromServer.h + prompt.viewportH;

    const submitButton = getSubmitButton(
      this,
      problemJS.paintCanvas,
      problemJS.submitAttempt,
      problemJS.getThemeColor
    );

    submitButton.viewportX = this.viewportW / 2 - submitButton.viewportW / 2;
    submitButton.viewportY =
      this.viewportH + this.VERTICAL_SPACE_BEFORE_SUBMIT_BUTTON;

    this.add(submitButton);

    this.viewportH +=
      submitButton.viewportH + this.VERTICAL_SPACE_BEFORE_SUBMIT_BUTTON;

    this.centerHorizontally();
  }

  getCargo(index: number): BasicObject {
    const ret = this.cargo.get(index);

    if (!ret) return this.problemJS.getStaticPanelFromText('cargo failure');

    return ret;
  }

  getSource(cargoIndex: number): Location | undefined {
    return this.locations.find(
      location => location.locationFromServer.sourceCargo === cargoIndex
    );
  }

  grabFocus(): void {
    this.problemJS.grabFocus(this);
  }

  serializeAttempt(): any {
    if (!this.dto.testing) {
      const unanswered = this.locations.find(
        location => !location.isSource() && !location.hasCurrentCargo()
      );

      if (unanswered !== undefined) {
        problemModalState().alert({
          msg: 'You have at least one empty box.',
          top: 'Invalid',
        });

        return;
      }
    }

    return { locations: this.dto.locations };
  }

  // Record and apply state sent from server
  update(updatedJson: DTO): void {
    this.dto.locations = updatedJson.locations;

    updatedJson.locations.forEach((locationFromServer, index) => {
      this.locations[index].locationFromServer = locationFromServer;

      // x and y are sometimes being changed by the client, sent to the server,
      // and then sent back to the client resulting in misplaced targets
      // this patch is to make sure we are using the values we want
      // (will be removed when we have a better solution)
      this.locations[index].locationFromServer.x = this.locations[
        index
      ].viewportX;
      this.locations[index].locationFromServer.y = this.locations[
        index
      ].viewportY;
    });
  }

  isDragging(): boolean {
    return this.dragging;
  }

  restoreSourceCargo(cargo: number): void {
    this.locations.forEach(location => {
      if (location.locationFromServer.sourceCargo === cargo) {
        location.locationFromServer.currentCargo = [cargo];
      }
    });
  }

  // Note that both 'source' and 'target' Locations can play either role
  // of 'cargoProvider' or 'cargoDestination'. This is mainly so that client
  // can drag cargo from a target Location back to its original source
  // Location
  setDragging(
    b: boolean,
    cargoProvider?: Location,
    cargoDestination?: Location,
    position?: { x: number; y: number }
  ): boolean {
    const changed = b !== this.dragging;

    if (!changed) return false;

    this.dragging = b;

    this.changedMaybe(this.agId);

    this.problemJS.$canvas.css(
      'cursor',
      this.dragging ? 'grabbing' : 'default'
    );

    // Client switched from dragging to not dragging
    if (!this.dragging) {
      $('#dragCanvas').remove();
      this.sourceCanvas = undefined;

      if (this.currentDraggingSource) {
        // Client was dragging cargo over a 'droppable' target
        if (cargoDestination) {
          cargoDestination.addCargo(this.currentDraggingSource.draggingCargo!);
          this.currentDraggingSource.removeCurrentCargo(
            this.currentDraggingSource.draggingCargo!
          );
        }

        this.currentDraggingSource.draggingCargo = undefined;
        this.currentDraggingSource = undefined;
      }

      this.problemJS.paintCanvas();

      return true;
    }

    if (!cargoProvider) {
      console.error('No source for drag');

      return true;
    }

    // If we get here, client triggered a new drag
    this.currentDraggingSource = cargoProvider;
    this.currentDraggingSource.draggingCargo = cargoProvider.getLastCurrentCargo();

    // A new 'floating' canvas to show the dragging cargo
    $(this.sourceCanvas)
      .parent()
      .append($.parseHTML("<canvas id='dragCanvas'></canvas>"));
    const $dragCanvas = $('#dragCanvas');

    $dragCanvas.css('position', 'absolute');
    $dragCanvas.css('pointer-events', 'none');
    $dragCanvas.css('transform', 'rotate(10deg)');

    // Applies our PIXEL_RATIO to handle high-res monitors (such as Mac retina)
    this.problemJS.resizeCanvas(
      $dragCanvas[0],
      cargoProvider.viewportW,
      cargoProvider.viewportH
    );

    this.moveDraggingImage(position?.x || 0, position?.y || 0);

    // happy typescript
    const can: any = $dragCanvas[0];
    const ctx = can.getContext('2d');

    // We only need to paint the cargo panel supplier once on our brand new 'floating' canvas.
    // Nice trick: send the floating canvas's ctx to the cargoProvider's logic and we'll
    // get a duplicate of the cargoProvider painted onto the floating canvas.
    cargoProvider.paintMe(ctx, true);

    return true;
  }

  moveDraggingImage(x: number, y: number): void {
    const insetX = $(this.sourceCanvas).position()?.left;

    if (insetX) x += insetX;
    const insetY = $(this.sourceCanvas).position()?.top;

    if (insetY) y += insetY;

    const $dragCanvas = $('#dragCanvas');

    const width = $dragCanvas.width();
    const height = $dragCanvas.height();

    // keeping typescript happy
    if (width === undefined || height === undefined) return;

    // adjust these if we don't like the pointer's position relative to the 'floating' canvas
    x -= width;
    y -= height / 2;
    $dragCanvas.css('left', x + 'px');
    $dragCanvas.css('top', y + 'px');
  }

  requestPointer(pointer: MousePointer): void {
    if (this.isDragging()) return;

    this.problemJS.$canvas.css('cursor', pointer);
  }

  isDraggingLocation(location: Location): boolean {
    return this.isDragging() && this.currentDraggingSource === location;
  }
}

type LocationStatus =
  | 'empty'
  | 'available'
  | 'unavailable'
  | 'grabbable'
  | 'droppable';

const getLocationStatus = function (location: Location): LocationStatus {
  if (!location.hasCurrentCargo()) {
    if (location.isSource()) {
      if (location.mouseIsOver && location.dragDropGetter.isDragging()) {
        return location.dragDropGetter.currentDraggingSource?.draggingCargo ===
          location.locationFromServer.sourceCargo
          ? 'droppable'
          : 'unavailable';
      }

      return 'unavailable';
    }

    return location.mouseIsOver &&
      location.dragDropGetter.isDragging() &&
      location.allowsCargo(
        location.dragDropGetter.currentDraggingSource!.draggingCargo!
      )
      ? 'droppable'
      : 'empty';
  }

  if (location.locationFromServer.correct) {
    return 'unavailable';
  }

  // if client is dragging this location's current cargo
  if (location.dragDropGetter.isDraggingLocation(location)) {
    if (location.isSource()) {
      return location.isInfiniteSource() ? 'available' : 'unavailable';
    }

    return 'unavailable';
  }

  if (!location.mouseIsOver) {
    return 'available';
  }

  if (location.dragDropGetter.isDragging()) {
    if (location.isSource()) return 'available';

    return location.allowsCargo(
      location.dragDropGetter.currentDraggingSource!.draggingCargo!
    )
      ? 'droppable'
      : 'unavailable';
  }

  return 'grabbable';
};

const BORDER_EMPTY_COLOR = 'black';
const BORDER_AVAILABLE_COLOR = 'black';
const BORDER_UNAVAILABLE_COLOR = 'gray';
const BORDER_GRABBABLE_COLOR = '#FFA500'; // 'orange'
const BORDER_DROPPABLE_COLOR = '#0000EE'; // 'blue'
const BORDER_CORRECT_COLOR = '#008000'; // 'green'
const BORDER_WRONG_COLOR = '#FF00FF'; // 'magenta'
const BACKGROUND_GRABBABLE_COLOR = '#FFA50020'; // faded orange
const BACKGROUND_DROPPABLE_COLOR = '#0000EE20'; // faded blue

const BORDER_THICK = 4;
const BORDER_THIN = 2;

const borderColors = new Map<LocationStatus, string>();

borderColors.set('empty', BORDER_EMPTY_COLOR);
borderColors.set('available', BORDER_AVAILABLE_COLOR);
borderColors.set('unavailable', BORDER_UNAVAILABLE_COLOR);
borderColors.set('grabbable', BORDER_GRABBABLE_COLOR);
borderColors.set('droppable', BORDER_DROPPABLE_COLOR);

class Location extends BasicObject {
  locationFromServer: LocationFromServer;
  dragDropGetter: DragDropGetter;
  padding: number;
  draggingCargo?: number;

  constructor(
    inboundLocation: LocationFromServer,
    dragDropGetter: DragDropGetter,
    padding: number
  ) {
    super();

    this.dragDropGetter = dragDropGetter;
    this.padding = padding;

    this.viewportX = inboundLocation.x;
    this.viewportY = inboundLocation.y;
    this.viewportW = inboundLocation.w;
    this.viewportH = inboundLocation.h;
    this.locationFromServer = inboundLocation;
  }

  allowsCargo(cargo: number): boolean {
    if (this.isSource()) {
      return this.locationFromServer.sourceCargo === cargo;
    }

    if (!this.locationFromServer.allowedCargo) return true;

    return (
      this.locationFromServer.allowedCargo.find(c => c === cargo) !== undefined
    );
  }

  removeCurrentCargo(cargo: number): void {
    if (this.isInfiniteSource()) return;

    this.locationFromServer.currentCargo = this.locationFromServer.currentCargo.filter(
      c => c !== cargo
    );
  }

  isAtCargoLimit(): boolean {
    return (
      this.locationFromServer.cargoLimit ===
      this.locationFromServer.currentCargo.length
    );
  }

  addCargo(cargo: number): void {
    // If client drops new cargo onto cargoDestination that already has 'old' cargo,
    // we move the 'old' cargo back to its original source.
    if (this.isAtCargoLimit()) {
      const remove = this.locationFromServer.currentCargo.pop();

      this.dragDropGetter.restoreSourceCargo(remove!);
    }

    this.locationFromServer.currentCargo.push(cargo);
  }

  hasCurrentCargo(): boolean {
    return this.locationFromServer.currentCargo.length > 0;
  }

  getLastCurrentCargo(): number {
    const c = this.locationFromServer.currentCargo;

    return c[c.length - 1];
  }

  isSource(): boolean {
    return this.locationFromServer.sourceCargo > -1;
  }

  isInfiniteSource(): boolean {
    return this.isSource() && this.locationFromServer.infinite;
  }

  currentCargoIsExactMatchOfIncorrectSubmission(): boolean {
    if (!this.isWrong()) return false;
    if (!this.locationFromServer.incorrectCargoSubmission) return false;

    return (
      JSON.stringify(this.locationFromServer.currentCargo) ===
      JSON.stringify(this.locationFromServer.incorrectCargoSubmission)
    );
  }

  isWrong(): boolean {
    return (
      this.locationFromServer.correct !== undefined &&
      this.locationFromServer.correct === false
    );
  }

  paintMe(
    ctx: CanvasRenderingContext2D,
    paintingOnFloatingDragCanvas?: boolean
  ): void {
    ctx.save();

    const status = getLocationStatus(this);

    ctx.strokeStyle = borderColors.get(status) || BORDER_EMPTY_COLOR;
    ctx.lineWidth = BORDER_THIN;

    ctx.fillStyle = 'white';

    if (status === 'grabbable') ctx.fillStyle = BACKGROUND_GRABBABLE_COLOR;
    if (status === 'droppable') ctx.fillStyle = BACKGROUND_DROPPABLE_COLOR;

    if (
      !this.dragDropGetter.dto.testing &&
      this.locationFromServer.correct !== undefined
    ) {
      ctx.lineWidth = BORDER_THICK;

      if (this.locationFromServer.correct)
        ctx.strokeStyle = BORDER_CORRECT_COLOR;
      // Ignore magenta (wrong) color if target no longer has incorrect cargo to
      // avoid confusing users -- they would see an empty target, or a target
      // with an altered attempt, as 'wrong'.
      // Also ignore magenta if client is currently able to drag or drop.
      else if (
        this.currentCargoIsExactMatchOfIncorrectSubmission() &&
        status !== 'empty' &&
        status !== 'droppable' &&
        status !== 'grabbable'
      )
        ctx.strokeStyle = BORDER_WRONG_COLOR;
      else ctx.lineWidth = BORDER_THIN;
    }

    if (status === 'grabbable' || status === 'droppable')
      ctx.lineWidth = BORDER_THICK;

    if (paintingOnFloatingDragCanvas) {
      ctx.lineWidth = BORDER_THIN;
      ctx.strokeStyle = 'black';
    }

    ctx.fillRect(0, 0, this.viewportW, this.viewportH);
    ctx.fill();

    if (!this.isSource() && !paintingOnFloatingDragCanvas)
      ctx.setLineDash([5, 5]);
    roundRect(ctx, 1, 1, this.viewportW - 2, this.viewportH - 2, 6);
    ctx.stroke();
    ctx.setLineDash([]);

    // even if not 'empty,' could be 'droppable' target with no current cargo
    if (status === 'empty' || (!this.hasCurrentCargo() && !this.isSource())) {
      ctx.restore();

      return;
    }

    const paintableCargo = this.hasCurrentCargo()
      ? this.locationFromServer.currentCargo
      : [this.locationFromServer.sourceCargo];

    paintableCargo.forEach(cargoIndex => {
      ctx.save();
      const cargoPainter = this.dragDropGetter.getCargo(cargoIndex);

      // fade if correct or source no longer has available cargo to show original location of depleted cargo
      if (
        !paintingOnFloatingDragCanvas &&
        (this.locationFromServer.correct ||
          (this.isSource() && !this.hasCurrentCargo()))
      ) {
        ctx.globalAlpha = 0.4;
      }

      // center cargo in source or target
      ctx.translate(
        (this.viewportW - cargoPainter.viewportW) / 2,
        (this.viewportH - cargoPainter.viewportH) / 2
      );
      cargoPainter.paint(ctx);
      ctx.restore();
      ctx.translate(cargoPainter.viewportW, 0);
    });

    ctx.restore();
  }

  mouseMoveResponse(): void {
    if (getLocationStatus(this) === 'grabbable')
      this.dragDropGetter.requestPointer('grab');
  }

  mouseUpResponse(): void {
    this.dragDropGetter.setDragging(
      false,
      undefined,
      getLocationStatus(this) === 'droppable' ? this : undefined
    );

    if (getLocationStatus(this) === 'grabbable')
      this.dragDropGetter.requestPointer('grab');
  }

  touchStartResponse(): boolean {
    return this.mouseDownResponse();
  }

  mouseDownResponse(): boolean {
    const status = getLocationStatus(this);

    if (status === 'grabbable' || status === 'available')
      this.dragDropGetter.setDragging(
        true,
        this,
        undefined,
        this.dragDropGetter.mostRecentMouseDown
      );

    // KNOWN: If there is a 'normalGetter' with focus and it is above this DnD getter,
    // then the problem canvas will suddenly shift up by the height of the
    // normalGetter on-screen keyboard.
    this.dragDropGetter.grabFocus();

    return true;
  }
}
