/**
 * returns true if the browser is running on a Mac OS
 */
export function isIos(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor;

  return /iPad|iPhone|iPod/.test(userAgent);
}

export function isAndroid(): boolean {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return false;
  }

  return /android/i.test(userAgent);
}
