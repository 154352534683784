import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { FC } from 'react';

const dollar = (
  <img style={{ maxHeight: '20px' }} src="GMM-icons/GMM-icon-dollar-gray.svg" />
);
const noDollar = (
  <img
    style={{ maxHeight: '20px' }}
    src="GMM-icons/GMM-icon-dollar-slashed.svg"
  />
);

interface TextLabelProps {
  text?: string | number | undefined;
  dateOrdinalIndicator?: string | undefined;
}

export const Label: FC<TextLabelProps> = ({ text, dateOrdinalIndicator }) => {
  let jsx: JSX.Element | undefined;

  switch (text) {
    case '$':
      jsx = dollar;
      break;
    case 'no$':
      jsx = noDollar;
      break;
  }

  return (
    <div style={style}>
      {!jsx && (
        <div style={style}>
          {text}
          {dateOrdinalIndicator && (
            <sup
              style={{
                fontSize: '0.6em',
                verticalAlign: 'top',
                position: 'relative',
                top: '-0.4em',
              }}
            >
              {dateOrdinalIndicator}
            </sup>
          )}
        </div>
      )}
      {!!jsx && jsx}
    </div>
  );
};

const style: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '75px',
  minWidth: '75px',
  maxWidth: '75px',
  fontSize: '16px',
  fontWeight: 700,
};
