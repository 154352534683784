import { useMemo } from 'react';

import { ExamProblem, NormalProblem, problemStore } from '../stores';
import { StatisticsProps } from '../types';
import { getProblemIds, isExamProblem, isNormalProblem } from '../utils';

const defaultStatisticsProps = (): StatisticsProps => ({
  mySkillAccuracy: 'N/A',
  mySkillAttempts: 0,
  lastThree: 'N/A',
  lastFive: 'N/A',
  lastTen: 'N/A',
  lastCorrectDate: 'N/A',
  problemid: 'N/A',
});

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export interface ProblemState {
  normalProblems: NormalProblem[];
  examCorrectionsProblems: NormalProblem[];
}

interface UseProblems {
  examProblems: ExamProblem[];
  examCorrectionsProblems: NormalProblem[];
  practiceCorrectionsProblems: NormalProblem[];
  normalProblems: NormalProblem[];
  assignedSkills: NormalProblem[];
  spiralReview: NormalProblem[];
  spiralReviewByMonth: Map<string, NormalProblem[]>;
  currentProblem: NormalProblem | ExamProblem | undefined;
  currentProblemStatistics: StatisticsProps;
  showRequestUnlockButton: boolean;
  hasProblems: boolean;
}

export const useProcessedProblems = (): UseProblems => {
  const [
    problems,
    orderBy,
    dollars,
    selectedID,
    lockedCount,
    autoUnlock,
  ] = problemStore(state => [
    state.problems,
    state.orderBy,
    state.dollars,
    state.selectedID,
    state.lockedCount,
    state.autoUnlockExamsProblems,
  ]);

  const problemIds = getProblemIds(problems);
  const currentProblem = problems[selectedID];

  const hasProblems = Object.keys(problems).length > 0;

  const currentProblemStatistics = useMemo(() => {
    const build = defaultStatisticsProps();

    if (!currentProblem || !isNormalProblem(currentProblem)) return build;
    // old scheme: server supplies number via 'daysSince'
    const problem = currentProblem as NormalProblem;

    if (!problem.lastCorrectDate) {
      const daysSince = problem.daysSince!;
      const text =
        daysSince < 0
          ? 'Never'
          : daysSince === 0
          ? 'Today'
          : daysSince === 1
          ? 'Yesterday'
          : daysSince + ' Days';

      build.lastCorrectDate = text;
    }
    // new scheme: server supplies date as String via 'lastCorrectDate'
    else {
      build.lastCorrectDate = problem.lastCorrectDate;
    }

    if (problem.myAllTime === 'N/A') {
      build.mySkillAccuracy = 'N/A';
      build.mySkillAttempts = 0;
    } else if (problem.myAllTime) {
      const i = problem.myAllTime.indexOf('/');
      const correct = parseInt(problem.myAllTime.substring(0, i));
      const attempts = parseInt(problem.myAllTime.substring(i + 1));
      const accuracy = Math.round((100 * correct) / attempts);

      build.mySkillAccuracy = accuracy + '%';
      build.mySkillAttempts = attempts;
    }

    build.lastThree = problem.lastThree;
    build.lastFive = problem.lastFive;
    build.lastTen = problem.lastTen;
    build.problemid = 'To Do';

    return build;
  }, [currentProblem]);

  const examProblems = useMemo(
    () =>
      problemIds
        .map(id => problems[id])
        .filter(problem => isExamProblem(problem)) as ExamProblem[],
    [problemIds, problems]
  );

  const examCorrectionsProblems = useMemo(
    () =>
      problemIds
        .map(id => {
          return problems[id];
        })
        .filter(
          problem =>
            isNormalProblem(problem) && problem.type === 'EXAM_CORRECTIONS'
        ) as NormalProblem[],
    [problemIds, problems]
  );

  const practiceCorrectionsProblems = useMemo(
    () =>
      problemIds
        .map(id => problems[id])
        .filter(
          problem =>
            isNormalProblem(problem) && problem.type === 'PRACTICE_CORRECTIONS'
        ) as NormalProblem[],
    [problemIds, problems]
  );

  const normalProblems = useMemo(
    () =>
      problemIds
        .map(id => problems[id])
        .filter(
          problem =>
            isNormalProblem(problem) &&
            (problem.type === 'PRACTICE_CORRECTIONS' ||
              problem.type === 'SPIRAL_REVIEW' ||
              problem.type === 'NEW_SKILL')
        ) as NormalProblem[],
    [problemIds, problems]
  );

  const assignedSkills = useMemo(
    () =>
      problemIds
        .map(id => problems[id])
        .filter(
          problem => isNormalProblem(problem) && problem.type === 'NEW_SKILL'
        ) as NormalProblem[],
    [problemIds, problems]
  );

  const spiralReview = useMemo(
    () =>
      problemIds
        .map(id => problems[id])
        .filter(
          problem =>
            isNormalProblem(problem) && problem.type === 'SPIRAL_REVIEW'
        ) as NormalProblem[],
    [problemIds, problems]
  );

  const spiralReviewByMonth = useMemo(() => {
    let ordered = new Map<string, NormalProblem[]>();

    if (orderBy === 'Date Learned') {
      const unordered = new Map<number, NormalProblem[]>();

      spiralReview.forEach(problem => {
        const split = problem.dateLearned?.split('-');
        const yearString = split?.[0];
        const year = parseInt(yearString!);
        const monthString = split?.[1];
        const month = parseInt(monthString!) - 1;
        const key = year * 12 + month;

        if (!unordered.has(key)) {
          unordered.set(key, new Array<NormalProblem>());
        }

        unordered.get(key)?.push(problem);
      });

      ordered = orderByMonth(unordered);
    } else if (orderBy === 'Date of Attempt') {
      const unordered = new Map<number, NormalProblem[]>();
      const never = spiralReview.filter(problem => !problem.lastAttempt);

      if (never.length > 0) {
        ordered.set('Never', never);
      }

      spiralReview
        .filter(problem => problem.lastAttempt)
        .forEach(problem => {
          const date = new Date(problem.lastAttempt);
          const key = date.getFullYear() * 12 + date.getMonth();

          if (!unordered.has(key)) {
            unordered.set(key, new Array<NormalProblem>());
          }

          unordered.get(key)?.push(problem);
        });

      orderByMonth(unordered).forEach((value, key) => {
        ordered.set(key, value);
      });
    } else if (orderBy === 'Skill Level') {
      ordered.set('white', new Array<NormalProblem>());
      ordered.set('red', new Array<NormalProblem>());
      ordered.set('yellow', new Array<NormalProblem>());
      ordered.set('green', new Array<NormalProblem>());
      ordered.set('silver', new Array<NormalProblem>());
      ordered.set('gold', new Array<NormalProblem>());

      spiralReview.forEach(problem => {
        if (problem.lvl === 'not enough data' || problem.lvl === 'untried') {
          ordered.get('white')?.push(problem);
        } else ordered.get(problem.lvl)?.push(problem);
      });
    } else {
      ordered.set(
        '$',
        spiralReview.filter(problem => dollars.includes(problem.id))
      );
      ordered.set(
        'no$',
        spiralReview.filter(problem => !dollars.includes(problem.id))
      );
    }

    return ordered;
  }, [problemIds, problems, orderBy, dollars]);

  const showRequestUnlockButton = lockedCount > 0 && !autoUnlock;

  return {
    examProblems,
    examCorrectionsProblems,
    practiceCorrectionsProblems,
    normalProblems,
    assignedSkills,
    spiralReview,
    spiralReviewByMonth,
    currentProblem,
    currentProblemStatistics,
    showRequestUnlockButton,
    hasProblems,
  };
};

/*
 * Helper function to order problems by month
 * @param unordered - Map of problems with year * 12 + month as key
 * @returns ordered - Map of problems with month and year as key
 * (or 'Past' as key if range of unordered map's keys is more than 11 months)
 */
const orderByMonth = (
  unordered: Map<number, NormalProblem[]>
): Map<string, NormalProblem[]> => {
  const ordered = new Map<string, NormalProblem[]>();

  const addEntry = (key: number): void => {
    if (!unordered.has(key)) {
      unordered.set(key, []);
    }

    ordered.set(formatKey(key), unordered.get(key)!);
  };

  const sortedKeys = Array.from(unordered.keys()).sort((a, b) => a - b);
  const firstKey = sortedKeys[0];
  const lastKey = sortedKeys[sortedKeys.length - 1];
  const range = lastKey - firstKey;

  if (range > 11) {
    const past = new Array<NormalProblem>();

    ordered.set('Past', past);
    sortedKeys.forEach(key => {
      if (key < lastKey - 10) {
        past.push(...unordered.get(key)!);
        unordered.delete(key);
      }
    });

    for (let key = lastKey - 10; key <= lastKey; key++) {
      addEntry(key);
    }
  } else {
    for (let key = firstKey; key <= firstKey + 11; key++) {
      addEntry(key);
    }
  }

  return ordered;
};

/*
 * Helper function to format the month/year key
 * @param key - year * 12 + month
 * @returns formattedKey - A formatted string with month and year
 */
const formatKey = (key: number): string => {
  const month = key % 12;
  const year = Math.floor(key / 12) % 100;

  return `${monthNames[month]} '${year}`;
};
