import { ImageDTO, NARROW_PROBLEM_WIDTH } from '..';
import { problemModalState } from '../modals';
import { buildTTSDTO } from '../readAloud';
import { lineFont, lineFontSize } from '../state';
import { BasicObject, TTSDTO, getSubmitButton } from '../uiObjects';
import { decodeString, distance } from '../utils';

import { AttemptGetter, AttemptGetterStatus, BaseDTO } from './attemptGetter';

interface HotSpotGetterDependencies {
  // reusing 'grabFocus' used by other getters in 'mega file'
  grabFocus(getter: AttemptGetter): void;

  // for aquiring grid or number line backing image
  getProbImage(data: any, x?: number, y?: number): BasicObject;

  // for building BasicObjects to render prompt
  getStaticPanelFromText(
    text: string,
    font?: string,
    tts?: TTSDTO
  ): BasicObject;

  // for getSubmitButton
  submitAttempt(): void;
  getThemeColor(): string;
  paintCanvas(): void;

  // for downsizing to fit narrow
  isNarrow(): boolean;
  canvasWidthNarrow: number;
}

interface DTO extends BaseDTO {
  prompt: string;

  // w, h, restoreId, and testing are part of building a url to access backing image on secondary call.
  // h and restoreId will no longer be needed when base 64 is the only way to send image.
  // (still need 'w' and 'testing')

  // width and height, not including prompt and submit button
  w: number;
  h: number;
  restoreId: number;
  testing: boolean;

  // Carries base 64 encoded image data (if that is turned on)
  backingImageDTO?: ImageDTO;

  // location and state of hot spots
  hotSpots: HotSpotFromServer[];
  // how many hot spot selections constitute a full answer
  requiredSelectionCount: number;

  // Server doesn't originally send this image-based object, but
  // once it gets built client-side on the first load of this getter, we don't
  // want it to get loaded again later, such as when students switch
  // between problems, so we add it to the 'DTO' here, which will ensure
  // it gets saved locally by the app using problem js
  backing?: any;
}

interface HotSpotFromServer {
  x: number;
  y: number;
  state: 'checked' | 'unchecked' | undefined;
  // not set if testing or if unsubmitted
  correct?: boolean;
}

export class HotSpotGetter extends AttemptGetter {
  HOT_SPOT_RADIUS = 6;
  CIRCLE_BORDER_COLOR = '#00acc8';
  SMIDGE_CHECKMARK = 1;
  SELECTED_COLOR = 'magenta';
  X_COLOR = 'magenta';

  problemJS: HotSpotGetterDependencies;

  dto: DTO;

  constructor(
    problemJS: HotSpotGetterDependencies,
    dtoFromServer: DTO,
    status: AttemptGetterStatus,
    changeHandler: any,
    x?: number,
    y?: number
  ) {
    super(dtoFromServer, status, changeHandler, x || 0, y || 0);
    this.problemJS = problemJS;
    this.dto = dtoFromServer;

    const decoded = decodeString(this.dto.prompt) ?? this.dto.prompt;
    const prompt = this.problemJS.getStaticPanelFromText(
      decoded,
      undefined,
      buildTTSDTO(decoded)
    );

    prompt.viewportH += this.VERTICAL_SPACE_AFTER_PROMPT;
    this.add(prompt);

    // We support image data arriving both as base 64 encoded strings and as data to build
    // callback url. If there is no 'backingImage' from the server, then we are using the
    // old url-based method, and we need to build the backingImage object here.
    // Remove this if we ever convert to only using base 64.
    if (!this.dto.backingImageDTO)
      this.dto.backingImageDTO = {
        w: dtoFromServer.w,
        h: dtoFromServer.h,
        restoreId: dtoFromServer.restoreId,
        test: dtoFromServer.testing,
      };

    this.dto.backing = problemJS.getProbImage({
      image: this.dto.backingImageDTO,
    });

    this.dto.backing.viewportY = prompt.viewportH;

    this.add(this.dto.backing);

    this.viewportW = Math.max(this.dto.backing.viewportW, prompt.viewportW);
    this.viewportH = this.dto.backing.viewportH + prompt.viewportH;

    const submitButton = getSubmitButton(
      this,
      problemJS.paintCanvas,
      problemJS.submitAttempt,
      problemJS.getThemeColor
    );

    submitButton.viewportX = this.viewportW / 2 - submitButton.viewportW / 2;
    submitButton.viewportY =
      this.viewportH + this.VERTICAL_SPACE_BEFORE_SUBMIT_BUTTON;

    this.add(submitButton);

    this.viewportH +=
      submitButton.viewportH + this.VERTICAL_SPACE_BEFORE_SUBMIT_BUTTON;

    this.centerHorizontally();
  }

  // in case Client in narrow mode downsized backing image
  getAdjustedHotSpotLocation(
    x: number,
    y: number,
    isNarrow: boolean
  ): { x: number; y: number } {
    if (!isNarrow || this.dto.w <= NARROW_PROBLEM_WIDTH) return { x: x, y: y };

    const downsizer = NARROW_PROBLEM_WIDTH / this.dto.w;

    return { x: downsizer * x, y: downsizer * y };
  }

  paintMe(ctx: CanvasRenderingContext2D): void {
    ctx.translate(this.dto.backing.viewportX, this.dto.backing.viewportY);

    const isNarrow = this.problemJS.isNarrow();

    this.dto.hotSpots.forEach(hotSpot => {
      ctx.save();

      const location = this.getAdjustedHotSpotLocation(
        hotSpot.x,
        hotSpot.y,
        isNarrow
      );

      // Magenta fill if selected but not submitted (or if testing, simply if selected)
      if (hotSpot.correct === undefined && hotSpot.state == 'checked') {
        ctx.fillStyle = this.SELECTED_COLOR;
        ctx.beginPath();
        ctx.arc(
          location.x,
          location.y,
          this.HOT_SPOT_RADIUS,
          0,
          2 * Math.PI,
          false
        );
        ctx.closePath();
        ctx.fill();
      }

      // blue sky circle border
      ctx.strokeStyle = this.CIRCLE_BORDER_COLOR;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.arc(
        location.x,
        location.y,
        this.HOT_SPOT_RADIUS,
        0,
        2 * Math.PI,
        false
      );
      ctx.closePath();
      ctx.stroke();

      // Green check over hotSpot that was correctly submitted while not testing
      if (hotSpot.correct) {
        ctx.beginPath();
        ctx.fillStyle = 'green';
        const fontSize = 1.5 * lineFontSize;

        ctx.font = 'bold ' + fontSize + 'px ' + lineFont;
        ctx.textBaseline = 'middle';
        const text = '\u2713';
        const metrics = ctx.measureText(text);

        ctx.fillText(
          text,
          location.x - metrics.width / 2 + this.SMIDGE_CHECKMARK,
          location.y - this.SMIDGE_CHECKMARK
        );
        ctx.fill();
      }

      // Magenta X over hotSpot that was incorrectly submitted while not testing
      if (hotSpot.correct !== undefined && !hotSpot.correct) {
        ctx.lineWidth = 2;
        ctx.strokeStyle = this.X_COLOR;
        // X extends 3 pixels beyond circle rim on both sides
        const side = this.HOT_SPOT_RADIUS * 2 + 6;

        ctx.translate(location.x - side / 2, location.y - side / 2);
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(side, side);
        ctx.moveTo(side, 0);
        ctx.lineTo(0, side);
        ctx.closePath();
        ctx.stroke();
      }

      ctx.restore();
    });
  }

  grabFocus(): void {
    this.problemJS.grabFocus(this);
  }

  serializeAttempt(): any {
    if (!this.dto.testing) {
      const selected = this.dto.hotSpots.filter(
        hotSpot => hotSpot.state === 'checked'
      );

      if (selected.length !== this.dto.requiredSelectionCount) {
        const spot =
          this.dto.requiredSelectionCount === 1 ? ' spot.' : ' spots.';

        problemModalState().alert({
          msg: `You need to select exactly ${this.dto.requiredSelectionCount} ${spot}`,
          top: 'Invalid',
        });

        return;
      }
    }

    return { hotSpots: this.dto.hotSpots };
  }

  // Record and apply state sent from server
  update(updatedJson: DTO): void {
    this.dto.hotSpots = updatedJson.hotSpots;
  }

  mouseDownResponse(x: number, y: number): boolean {
    if (!this.isEnabled()) return false;

    y -= this.dto.backing.viewportY;
    x -= this.dto.backing.viewportX;

    let hitSpot: HotSpotFromServer | undefined;

    const isNarrow = this.problemJS.isNarrow();

    this.dto.hotSpots.forEach(hotSpot => {
      if (hotSpot.correct !== undefined) return;

      const location = this.getAdjustedHotSpotLocation(
        hotSpot.x,
        hotSpot.y,
        isNarrow
      );

      if (distance(location.x, location.y, x, y) <= this.HOT_SPOT_RADIUS) {
        hitSpot = hotSpot;
      }
    });

    if (hitSpot === undefined) return false;

    // unchecking is always allowed
    if (hitSpot.state === 'checked') {
      hitSpot.state = 'unchecked';
    }
    // single answer: uncheck everything, then select this hotspot
    else if (this.dto.requiredSelectionCount === 1) {
      this.dto.hotSpots.forEach(hotSpot => {
        hotSpot.state = 'unchecked';
      });
      hitSpot.state = 'checked';
    }
    // multiple answers: only allow <= 'answers count' to be checked otherwise ignore
    else {
      let checkedCount = 0;

      this.dto.hotSpots.forEach(hotSpot => {
        if (hotSpot.state === 'checked') checkedCount++;
      });
      if (checkedCount >= this.dto.requiredSelectionCount) return false;
      hitSpot.state = 'checked';
    }

    this.changedMaybe(this.agId);

    this.grabFocus();

    return true;
  }
}
