import { create } from 'zustand';
import { devtools as dev } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Score } from '@gmm/problem';

import {
  postOrderBy,
  postRestoreIdSelected,
  postSawRestoreTest,
  sendErrorToServer,
  sendSkipsUpdate,
} from '../api';
import { alerts } from '../api/alerts';
import { receiveProblem, unlockAll } from '../api/responseHandlerShared';
import { sendRestoreRequest } from '../api/sendRestore';
import { activity } from '../legacy/activityMonitor';
import {
  DateString,
  ExamProblems,
  ID,
  NormalProblems,
  OrderOption,
  ProblemObjects,
  Problems,
  ProblemSet,
  Proficiency,
  NewWorkProblems,
  DEFAULT_ORDER_BY,
} from '../types';
import {
  gmmAlert,
  getOrderedIDs,
  isExamProblem,
  ProblemShared,
  getExamOrderedIds,
  isNormalProblem,
} from '../utils';
import {
  harvestTimeSeen,
  isLandscape,
  logMd5,
  logProblemsAndWork,
} from '../utils/gmmUtils';

import { bannerState } from './bannerStore';
import { getGmm, getIsTest, getMd5s } from './globalState';
import { problemJsonMap } from './problemJsonMap';
import { studentAppModalState } from './studentAppModalStore';

export type ProblemType =
  | 'NEW_SKILL'
  | 'SPIRAL_REVIEW'
  | 'EXAM_CORRECTIONS'
  | 'PRACTICE_CORRECTIONS';

export type NormalProblem = {
  skillId: number;
  daysSince: number;
  firstTry: boolean;
  followUp: boolean;
  hasBeenTried: boolean;
  id: string;
  isUnfixedExamCorrection: boolean;
  type: ProblemType;
  lastAttempt: number;
  lastCorrectDate: 'Never' | 'Today' | 'Yesterday' | DateString;
  lastFive: Score;
  lastTen: Score;
  lastThree: Score;
  lvl: Proficiency;
  myAllTime: Score;
  md5s?: string[];
  penalty: number;
  rawScore: number;
  // How many times the student has looked at this problem,
  // then clicked on another Skill
  skips: number;
  status?: 'right' | 'wrong';
  assigned?: boolean;
  dateLearned?: string;
};

export const initialNormalProblem = {
  skillId: 0,
  daysSince: 7,
  firstTry: true,
  followUp: false,
  hasBeenTried: false,
  id: '1',
  isUnfixedExamCorrection: false,
  lastAttempt: 0,
  lastCorrectDate: 'Never' as DateString,
  lastFive: 'N/A' as Score,
  lastTen: 'N/A' as Score,
  lastThree: 'N/A' as Score,
  lvl: 'red' as Proficiency,
  myAllTime: 'N/A' as Score,
  penalty: 0,
  rawScore: 0,
  skips: 0,
};

export type ExamProblem = {
  id: string;
  locked: boolean;
  notValid: boolean;
  order: number;
  ready: boolean;
  seen: boolean;
  testNumber: number;
  uncertain?: boolean;
};

export const initialExamProblem = {
  id: '',
  locked: false,
  notValid: false,
  order: 1,
  ready: false,
  testNumber: 1,
  seen: false,
};

type LevelingUp = {
  id: string;
  lvl: Proficiency;
};

export interface ProblemStoreState {
  autoUnlockExamsProblems: boolean;
  dollars: string[];
  dollarsOnly: boolean;
  levelingUp: LevelingUp;
  lockedCount: number;
  orderBy: OrderOption;
  problems: Problems;
  selectedID: string;
  // As in, "I'm doing #7"
  currentExamProblemNumber?: number;
  noProblemsOnLogin: boolean;
}

export interface ProblemStore extends ProblemStoreState {
  clearLevelingUp: () => void;
  setAutoUnlockExamsProblems: (autoUnlock: boolean) => void;
  setDetails: (problems: ProblemSet) => void;
  clear: () => void;
  setDollars: (dollarArray: string[]) => void;
  setDollarsOnly: (value: boolean) => void;
  setLockedCount: (lockedCount: number) => void;
  setMd5: (id: string, md5: string) => void;
  setOrderBy: (orderBy: OrderOption) => void;
  setPenalty: (id: string, amount: number) => void;
  setSelectedID: (id: string, type?: string) => void;
  updateProblem: (problemID: string, problem: Partial<ProblemShared>) => void;
  resetSquare: (problemId: string) => void;
  setNoProblemsOnLogin: (value: boolean) => void;
}

(window as any).__REDUX_DEVTOOLS_EXTENSION__;

// Vanilla store. React will wrap in create from 'zustand'
export const problemStore = create<ProblemStore>()(
  dev(
    immer((set, get) => ({
      autoUnlockExamsProblems: true,
      dollars: [],
      dollarsOnly: false,
      lockedCount: 0,
      levelingUp: { id: '', lvl: 'red' },
      orderBy: DEFAULT_ORDER_BY,
      problems: {},
      selectedID: '',
      noProblemsOnLogin: false,
      setNoProblemsOnLogin: (value: boolean) =>
        set({ noProblemsOnLogin: value }),
      clearLevelingUp: () => set({ levelingUp: { id: '', lvl: 'red' } }),
      setAutoUnlockExamsProblems: (autoUnlock: boolean) => {
        set(draft => {
          if (!autoUnlock) {
            draft.autoUnlockExamsProblems = false;
          } else {
            draft.autoUnlockExamsProblems = true;
            Object.values(draft.problems).map(prob => {
              const problem = draft.problems[prob.id] as ExamProblem;

              problem.locked = false;
            });
          }
        });
      },
      clear: () => {
        set({ problems: {} });
        set({ dollars: [] });
        set({ selectedID: '' });
        set({ lockedCount: 0 });

        logProblemsAndWork('problemStore.clear');
      },

      // call with empty Record or undefined to wipe out
      // such as on login with no problems assigned to class
      setDetails: (problemSet: ProblemObjects | undefined) => {
        if (!problemSet || Object.keys(problemSet).length === 0) {
          get().clear();

          return;
        }

        const problems = Object.entries(problemSet).reduce<Problems>(
          (problemsWithId, [id, problem]) => {
            if (isExamProblem(problem)) {
              problemsWithId[id] = {
                ...problem,
                id,
                locked: !problem.unseen && !get().autoUnlockExamsProblems,
                notValid: !!problem.notValid,
                order: problem.tn,
                ready: problem.ready,
                seen: !problem.unseen,
                testNumber: problem.tn,
              } as ExamProblem;

              const examProblem = problemsWithId[id] as ExamProblem;

              if (examProblem.locked) {
                set(({ lockedCount }) => ({ lockedCount: lockedCount + 1 }));
              }
            } else {
              set({ lockedCount: 0 });
              problemsWithId[id] = {
                ...problem,
                id,
                followUp: problem.followUp || false,
                isUnfixedExamCorrection: problem.isUnfixedExamCorrection,
                lvl: problem.lvl,
                penalty: problem.penalty || 0,
                skips:
                  problem.skips && problem.skips !== null ? problem.skips : 0,
                status: problem.hasBeenTried
                  ? problem.firstTry
                    ? 'right'
                    : 'wrong'
                  : undefined,
              } as NormalProblem;
            }

            return problemsWithId;
          },
          {}
        );

        set({ problems });
      },
      setDollars: (dollars: string[]) => set({ dollars }),
      setDollarsOnly: (value: boolean) => {
        if (value === get().dollarsOnly || getIsTest()) {
          set({ dollarsOnly: value });
        } else {
          const dollarSelected = get().dollars.includes(get().selectedID);

          if (!dollarSelected) {
            const { dollars, problems } = get();

            const weakestID = getOrderedIDs({
              dollars,
              orderBy: 'Skill Level',
              problems,
              dollarsOnly: value,
            })[0];

            if (weakestID) {
              normalBoxClick(weakestID);
            }
          }

          set({ dollarsOnly: value });
        }
      },
      setLockedCount: (lockedCount: number) => set({ lockedCount }),
      setMd5: (id: string, md5: string) => {
        set(({ problems }) => {
          const problem = problems[id] as NormalProblem;

          if (!problem) return;

          if (!problem.md5s) {
            problem.md5s = [md5];
          } else if (problem && problem.md5s && problem.md5s.length >= 1) {
            problem.md5s.push(md5);
          }
        });
      },
      setOrderBy: (orderBy: OrderOption) => {
        set({ orderBy });

        postOrderBy(orderBy);
      },
      setPenalty: (id: string, amount: number) => {
        set(state => {
          const updatedProblems = { ...state.problems };

          if (updatedProblems[id]) {
            updatedProblems[id] = {
              ...updatedProblems[id],
              penalty: amount,
            };
          }

          return { problems: updatedProblems };
        });
      },
      setSelectedID: (id: string) => {
        set(draft => {
          draft.selectedID = id;
        });

        const problem = getSelectedProblem();

        set({
          currentExamProblemNumber: isExamProblem(problem)
            ? problem.order
            : undefined,
        });
      },
      resetSquare: (restoreId: string) => {
        const problem = get().problems[restoreId] as NormalProblem;

        if (!problem) return;

        problemState().updateProblem(restoreId, {
          status: 'right',
          penalty: 0,
          skips: 0,
        });
      },
      updateProblem: (restoreId: string, problem: Partial<ProblemShared>) => {
        set(draft => {
          // temporary removal of modal animation to ensure color changes show
          Object.assign(draft.problems[restoreId], problem);

          // if (
          //   'lvl' in problem &&
          //   colorsArray.includes(problem.lvl as Proficiency) &&
          //   problemID !== draft.levelingUp.id
          // ) {
          //   const current = draft.problems[problemID] as NormalProblem;
          //   const currColor = colorData[current.lvl];
          //   const latestColor = colorData[problem.lvl as Proficiency];

          //   if (latestColor.level > currColor.level) {
          //     draft.levelingUp = {
          //       id: problemID,
          //       lvl: problem.lvl as Proficiency,
          //     };

          //     const latestProblem = omit(problem, 'lvl');

          //     if (isEmpty(latestProblem)) return;

          //     Object.assign(draft.problems[problemID], latestProblem);
          //   } else {
          //     Object.assign(draft.problems[problemID], problem);
          //   }
          // } else {
          //   Object.assign(draft.problems[problemID], problem);
          // }
        });

        // Additional logic to update other problems with the same skillId
        const target = get().problems[restoreId];

        if (isNormalProblem(target)) {
          set(draft => {
            Object.values(draft.problems).forEach(problem => {
              if (
                isNormalProblem(problem) &&
                problem.skillId === target.skillId
              ) {
                if (problem.id !== restoreId) {
                  problem.lvl = target.lvl;
                  problem.lastFive = target.lastFive;
                  problem.lastTen = target.lastTen;
                  problem.lastThree = target.lastThree;
                  problem.myAllTime = target.myAllTime;
                  problem.daysSince = target.daysSince;
                  problem.lastCorrectDate = target.lastCorrectDate;
                  problem.lastAttempt = target.lastAttempt;
                  problem.rawScore = target.rawScore;
                }
              }
            });
          });
        }
      },
    })),
    { name: 'Problem Store' }
  )
);

export const problemState = (): ProblemStore => problemStore.getState();

export const getNumUnfinished = (): number => {
  const numUnFinished = Object.values(problemState().problems).filter(
    problem => !problem.ready
  ).length;

  return numUnFinished;
};

export const clearAllPenalties = (): void => {
  if (getIsTest()) return;

  const problems = problemState().problems;

  Object.values(problems).forEach((problem: NormalProblem) => {
    problemState().setPenalty(problem.id, 0);
  });
};

export const normalBoxClick = (id: string): void => {
  const curr = problemState().problems[
    problemState().selectedID
  ] as NormalProblem;
  const dollarIDs = problemState().dollars;
  const isDollar = dollarIDs.includes(curr.id);
  const teacherOnline = bannerState().teacherOnline;

  if (isDollar && !isSelected(id)) {
    if (
      curr.type !== 'EXAM_CORRECTIONS' &&
      curr.type !== 'PRACTICE_CORRECTIONS'
    ) {
      if (curr.skips > 3 && teacherOnline) {
        gmmAlert(alerts.notFinished);

        return;
      }
    }

    const totalSkips = curr.skips + 1;

    problemState().updateProblem(curr.id, {
      skips: totalSkips,
    });

    const delta = harvestTimeSeen() || 0;

    if (delta > 1500) {
      sendSkipsUpdate({ id: curr.id, skips: totalSkips, delta });
    } else {
      sendSkipsUpdate({ id: curr.id, skips: totalSkips });
    }
  } else {
    harvestTimeSeen(true);
  }

  setProblem(id);
};

export const examBoxClick = (id: string): void => {
  const problem = problemState().problems[id] as ExamProblem;

  if (problem.locked) return;

  problemState().updateProblem(id, {
    seen: true,
  });

  setProblem(id);

  if (!problem.ready && !problem.notValid) {
    postSawRestoreTest(problem.id);
  }
};

export const problemNotFound = (id: string): string => {
  const msg =
    '\n@@@@@@@@@@@@@@@@@@@\n' +
    "Can't set problem to " +
    id +
    " cuz boxes[id] doesn't exist!\n" +
    'This is likely due to sequence:\n' +
    '1) Get problem right, wait for response\n' +
    '2) Separately, ping returns new work, such as follow up or assignment, that changes probs (squares)\n' +
    "3) Server responds to correct problem from step 1 with suggestion for 'weakest' that's now gone\n" +
    '@@@@@@@@@@@@@@@@@@\n';

  return msg;
};

export const getSelectedProblem = (): ProblemShared => {
  const selectedID = problemState().selectedID;
  const selectedProblem = problemState().problems[selectedID];

  return selectedProblem;
};

export const isSelected = (id: string): boolean => {
  const currentProblem = getSelectedProblem();

  return currentProblem ? currentProblem.id === id : false;
};

export function setProblems(data: NewWorkProblems, isTest?: boolean): void {
  problemJsonMap.clear();
  problemState().clear();

  problemState().setDetails(data.problems);

  if (!isTest) {
    if (data.dollarRestoreIds) {
      problemState().setDollars(
        data.dollarRestoreIds.map(dollar => `${dollar}`)
      );
    }

    if (data.selectedRestoreId) {
      setProblem(data.selectedRestoreId);
    }
  } else if (isTest) {
    const problems = problemState().problems as ExamProblems;
    const orderedIDs = getExamOrderedIds(problems);

    // Find the first problem that is clickable and click it to force
    // load of problem state and focus on square
    const found = orderedIDs
      .map(id => problems[id])
      .find((problem: ExamProblem) => !problem.locked && !problem.ready);

    if (found) {
      examBoxClick(found.id);
    } else if (!data.autoUnlockExamsProblems) {
      gmmAlert({
        msg:
          "All the exam problems are locked. You can click 'Request Unlock.'",
        top: 'Locked Out!',
      });
    }
  }

  problemState().setAutoUnlockExamsProblems(!!data.autoUnlockExamsProblems);

  if (data.autoUnlockExamsProblems) {
    unlockAll();
  }

  resetOldest();
}

export function setProblem(id: ID): void {
  activity();

  const problemDetails = problemState().problems[`${id}`];

  if (!problemDetails) {
    sendErrorToServer(problemNotFound(`${id}`));

    return;
  }

  const problem = getSelectedProblem() as ExamProblem;

  // When a student switches to another problem during an exam,
  // auto-submit their work on the problem they were working on
  if (problem && isExamProblem(problem)) {
    if (problem.uncertain) {
      problemState().updateProblem(problem.id, {
        uncertain: false,
      });
      getGmm()?.submitAllAgs(function () {
        setProblem(id);
      });

      return;
    }
  }

  problemState().setSelectedID(`${id}`);

  postRestoreIdSelected(`${id}`);

  if (!problemJsonMap.has(`${id}`)) {
    getGmm()?.setToBlank();
    getProblem(id, getIsTest()!);

    return;
  }

  if (!isLandscape()) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  } else {
    // If there is a div that wraps the ProblemCanvas element, scroll to the top of it
    const scrollableDivs = document.getElementsByClassName(
      'scrollable-problem'
    );

    if (scrollableDivs.length > 0) {
      scrollableDivs[0].scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const p = problemJsonMap.get(`${id}`);

  // happy compiler
  if (!p) return;

  if (p.md5) {
    problemState().setMd5(`${id}`, p.md5);
  }

  // @ts-expect-error This should be an object, not an array
  getMd5s()[`${id}`] = p.md5;
  logMd5(id, p.md5);

  // unary plus to convert possible string to number
  getGmm()?.setProblem(p, +id);

  studentAppModalState().setLoading(false);

  resetOldest();
}

// Someday: oldest is derived from analysis of problems,
// so figure it out in custom hook useProcessedProblemState
export function resetOldest(): void {
  if (getIsTest()) return;
  let oldest = -2;

  if (!getIsTest()) {
    let f = false;

    $.each(problemState().problems as NormalProblems, function (_, val) {
      if (val.daysSince === -1) {
        oldest = -1;
        f = true;
      }

      if (!f) oldest = Math.max(oldest, val.daysSince);
    });
  }

  const result =
    oldest < 0
      ? 'Never'
      : oldest === 0
      ? 'Today'
      : oldest == 1
      ? 'One Day'
      : `${oldest} Days`;

  bannerState().setOldest(result);
}

function getProblem(id: ID, isTest: boolean): void {
  studentAppModalState().setLoading(true);

  sendRestoreRequest(`${id}`, isTest, {
    onSuccess: problem => receiveProblem({ problem, id }),
    onAxiosError: ({ status, error }) => {
      // set to blank in case of fail on server
      getGmm()?.setToBlank();

      const msg = `ServletRestore failed\n ${status} \n ${error}`;

      sendErrorToServer(msg);
      studentAppModalState().setLoading(false);
      gmmAlert(alerts.connectionFailure);
    },
  });
}
