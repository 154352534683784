import {
  BuildProblemCanvasOwnerParams,
  ProblemCanvasOwner,
  ProblemData,
  ProblemWidth,
} from '../index';
import { loadFonts } from '../utils';

import { buildProblemCanvasOwner as buildProblemCanvasHelper } from './problemCanvasOwner';

// Vestigial, overdue for removal
export const VERSION = '1.0';

let apiUrl = process.env.JAVA_URL;

export function getApiUrl(): string | undefined {
  return apiUrl;
}

export function setApiUrl(url: string): void {
  apiUrl = url;
}

// If you're an app that wants an interactive problem,
// 1) Use this function to acquire a ProblemCanvasOwner,
// 2) <Optional> set the returned problemCanvasOwner's ProblemContext (all or Partial)
// 3) Add a ProblemCanvas react element to your app, passing the ProblemCanvasOwner as a prop.
export const buildProblemCanvasOwner = function ({
  problemContext,
  isStudent,
  isTeacherViewingExamProblem,
}: BuildProblemCanvasOwnerParams = {}): ProblemCanvasOwner {
  loadFonts(problemContext?.errorToServer);

  return buildProblemCanvasHelper({
    isStudent,
    problemContext,
    isTeacherViewingExamProblem,
  }) as ProblemCanvasOwner;
};

// Not intended for use outside of @gmm/problem package.
// If you're an app and you want one of these, use StaticProblemCanvas.
export const displayStaticProblem = function (
  canvas: HTMLCanvasElement,
  problemData: ProblemData,
  width: ProblemWidth
): void {
  loadFonts();
  const owner = buildProblemCanvasOwner();

  owner.setCanvas(canvas, width);
  owner.setProblem(problemData);
};

export const ProblemFactory = {
  getApiUrl,
  setApiUrl,
  version: VERSION,
};
