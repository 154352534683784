import { BasicObject, floater } from '../uiObjects';

export const MenuConstants = {
  MENU_HORIZONTAL_MARGIN: 8,
  MENU_STANDARD_BACKCOLOR: 'white',
  MENU_STANDARD_BORDER_THICKNESS: 2,
  MENU_STANDARD_BORDER_COLOR: '#696969',
};

interface FloatingMenuDependencies {
  getText(
    x: number,
    y: number,
    text: string,
    fontSize?: number,
    multiline?: boolean,
    maxW?: number
  ): any;
}

export function getWordMenu(
  problemJS: FloatingMenuDependencies,
  words: string[],
  listener: any,
  painter?: any,
  dim?: { w: number; h: number },
  multi?: any,
  skipDividers?: boolean,
  hidden?: boolean
): any {
  const rows = new Array<BasicObject>();
  let mw = 0;

  if (!painter) {
    words.forEach((word: string) => {
      const row = new BasicObject();
      const text = problemJS.getText(0, 0, word);

      text.fillStyle = 'white';
      text.viewportY = 4;
      row.add(text);

      row.mouseDownResponse = function () {
        listener(word);

        return true;
      };

      row.sizeMeToFitChildren();
      row.viewportH = text.viewportH + 8;
      rows.push(row);
      mw = Math.max(mw, row.viewportW);
    });
  } else {
    words.forEach((word: string) => {
      const img = new BasicObject();

      img.skipClip = true;

      img.setAllDim(dim!.w, dim!.h);

      img.paintMe = function (ctx) {
        painter(ctx, word, 'white');
      };

      img.mouseDownResponse = function () {
        listener(word);

        return true;
      };

      rows.push(img);
      mw = Math.max(mw, img.viewportW);
    });
  }

  rows.forEach(row => {
    row.viewportW = mw;
  });

  return multi
    ? getMultiMenu(rows, multi, hidden || false)
    : getMenu(rows, skipDividers, hidden || false);
}

export function getMultiMenu(
  options: BasicObject[],
  maxHeight: number,
  hidden?: boolean
): BasicObject {
  const columnsPerRow: number = Math.ceil(options.length / maxHeight);
  const totalRows: number = Math.ceil(options.length / columnsPerRow);
  const rows: any = [];
  let maxColumnWidth = 0;
  let maxColumnHeight = 0;

  for (let r = 0; r < totalRows; r++) {
    const row = new BasicObject();

    for (let c = 0; c < columnsPerRow; c++) {
      const index = r * columnsPerRow + c;

      if (index >= options.length) break;

      const i = options[index];
      const column = new BasicObject();

      i.viewportY = 4;
      column.add(i);
      column.viewportH = i.viewportH + 8;
      column.viewportW = i.viewportW;

      column.mouseDownResponse = i.mouseDownResponse;
      maxColumnWidth = Math.max(maxColumnWidth, column.viewportW);
      maxColumnHeight = Math.max(maxColumnHeight, column.viewportH);
      row.add(column);

      if (c != columnsPerRow - 1) {
        const div = new BasicObject();

        div.setAllDim(2, column.viewportH + 4);
        div.fill = 'darkgray';
        row.add(div);
      }
    }

    rows[r] = row;
  }

  for (let r = 0; r < rows.length; r++) {
    const row = rows[r];

    for (let c = 0; c < row.children.length; c += 2) {
      row.children[c].viewportW = maxColumnWidth;
      row.children[c].viewportH = maxColumnHeight;
    }

    row.viewportH = maxColumnHeight;
    row.viewportW = maxColumnWidth * columnsPerRow + 2 * (columnsPerRow - 1);
    row.layoutChildrenLR(0);
  }

  return getMenu(rows, false, hidden || false);
}

interface Menu extends BasicObject {
  setBackgroundColor?(color: string): void;
}

export function getMenu(
  children: BasicObject[],
  skipDividers?: boolean,
  hidden?: boolean
): Menu {
  let iw = 0;

  for (let a = 0; a < children.length; a++) {
    iw = Math.max(iw, children[a].viewportW);
  }

  const inner = new BasicObject();

  inner.skipClip = true;

  if (!hidden) floater.setPanel(inner);

  for (let b = 0; b < children.length; b++) {
    (function (a) {
      inner.add(children[a]);

      if (!skipDividers && a != children.length - 1) {
        const div = new BasicObject();

        div.setAllDim(iw, 2);
        div.fill = 'darkgray';
        inner.add(div);

        div.mouseDownResponse = function (): boolean {
          return children[a].mouseDownResponse?.() || false;
        };
      }
    })(b);
  }

  const wm = MenuConstants.MENU_HORIZONTAL_MARGIN;
  const hm = 5;

  inner.layoutChildrenUD(0);
  inner.sizeMeToFitChildren();
  inner.fill = 'gray';

  const ret: Menu = new BasicObject();

  ret.mouseDownResponse = function (x: number, y: number): boolean {
    if (y < 0 || y > ret.viewportH || x < 0 || x > ret.viewportW) return false;

    for (let a = 0; a < children.length; a++) {
      const ch = children[a];

      if (y <= ch.viewportY + ch.viewportH + hm + 2) {
        ch.mouseDownResponse?.();

        return true;
      }
    }

    children[children.length - 1].mouseDownResponse?.();

    return true;
  };

  ret.viewportFill = 'gray';
  const w: number = inner.viewportW + wm * 2;
  const h: number = inner.viewportH + hm * 2;

  ret.setAllDim(w, h);
  ret.add(inner);
  inner.viewportX = wm;
  inner.viewportY = hm;
  ret.rounded = true;
  ret.gmmName = 'menu';

  ret.setBackgroundColor = function (color: string): void {
    ret.viewportFill = color;
    inner.fill = color;
  };

  return ret;
}
